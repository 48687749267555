<section fxLayout="column" fxLayoutGap="16px">
  <header fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <h3>{{ advancedSearchFilter.title }}</h3>
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </header>
  <app-filter appAutoFocus (filteredTextChanged)="filter($event)"></app-filter>
  <div [fxHide]="!noFilterFound" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
    <div class="no-filter-found-img" alt="no-filter-found"></div>
    <span class="no-filter-found-description">{{ 'GENERAL.ADVANCED_SEARCH.NO_CONTENT' | translate }}</span>
  </div>
  <article [fxHide]="noFilterFound" fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutGap="4px">
      <mat-checkbox [checked]="allSelected" [indeterminate]="someSelected() && !allSelected" (change)="setAll($event.checked)">{{ selectAllLabel | translate }}</mat-checkbox>
      <mat-checkbox *ngIf="filteredText !== ''" [(ngModel)]="addCurrentSelections">{{ 'GENERAL.ADVANCED_SEARCH.ADD_CURRENT_SELECTION' | translate }}</mat-checkbox>
    </div>
    <mat-divider></mat-divider>
    <form #checkboxForm [formGroup]="advancedSearchForm" fxLayout="column" fxLayoutGap="4px">
      <mat-checkbox
        *ngFor="let option of filterOptions"
        #filterOptionTooltip
        matTooltip="{{ option.text }}"
        matTooltipClass="positionClass"
        matTooltipPosition="above"
        [name]="option.value"
        [fxHide]="!option.display"
        [formControlName]="option.value"
        (change)="updateAllSelected()"
        ><span class="checkbox-text">{{ option.text }}</span>
      </mat-checkbox>
    </form>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
      <button class="vdw-button vdw-primary-button" [disabled]="!someSelected() && !addCurrentSelections" (click)="confirm()">{{ 'GENERAL.ACTIONS.CONFIRM' | translate }}</button>
      <a class="clear-filter-link" (click)="setAll(false)">{{ 'GENERAL.ADVANCED_SEARCH.CLEAR' | translate }}</a>
    </div>
  </article>
</section>
