// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const ICONS = [
  {
    "size": "16px",
    "icons": [
      {
        "path": "assets/icons/16px/add-person.svg",
        "name": "add-person",
        "dimensions": {
          "width": 25,
          "height": 22
        }
      },
      {
        "path": "assets/icons/16px/arrow-down.svg",
        "name": "arrow-down",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/arrow-right.svg",
        "name": "arrow-right",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/check.svg",
        "name": "check",
        "dimensions": {
          "width": 17,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/checked-radius.svg",
        "name": "checked-radius",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/checked.svg",
        "name": "checked",
        "dimensions": {
          "width": 14,
          "height": 14
        }
      },
      {
        "path": "assets/icons/16px/chevron-down.svg",
        "name": "chevron-down",
        "dimensions": {
          "width": 14,
          "height": 9
        }
      },
      {
        "path": "assets/icons/16px/clock.svg",
        "name": "clock",
        "dimensions": {
          "width": 14,
          "height": 14
        }
      },
      {
        "path": "assets/icons/16px/copy.svg",
        "name": "copy",
        "dimensions": {
          "width": 16,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/cross-sm.svg",
        "name": "cross-sm",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/delete.svg",
        "name": "delete",
        "dimensions": {
          "width": 17,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/dots.svg",
        "name": "dots",
        "dimensions": {
          "width": 16,
          "height": 4
        }
      },
      {
        "path": "assets/icons/16px/drop-down-arrow.svg",
        "name": "drop-down-arrow",
        "dimensions": {
          "width": 12,
          "height": 7
        }
      },
      {
        "path": "assets/icons/16px/drop-down-pagination.svg",
        "name": "drop-down-pagination",
        "dimensions": {
          "width": 17,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/edit.svg",
        "name": "edit",
        "dimensions": {
          "width": 17,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/editing.svg",
        "name": "editing",
        "dimensions": {
          "width": 17,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/exclamation-circle.svg",
        "name": "exclamation-circle",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/fail.svg",
        "name": "fail",
        "dimensions": {
          "width": 10,
          "height": 10
        }
      },
      {
        "path": "assets/icons/16px/fax.svg",
        "name": "fax",
        "dimensions": {
          "width": 16,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/file-add.svg",
        "name": "file-add",
        "dimensions": {
          "width": 14,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/file-doc.svg",
        "name": "file-doc",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/file-docx.svg",
        "name": "file-docx",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/file-export.svg",
        "name": "file-export",
        "dimensions": {
          "width": 17,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/file-jpg.svg",
        "name": "file-jpg",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/file-pdf-16px.svg",
        "name": "file-pdf-16px",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/file-search.svg",
        "name": "file-search",
        "dimensions": {
          "width": 17,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/file-xls.svg",
        "name": "file-xls",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/file-xlsx.svg",
        "name": "file-xlsx",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/first-page.svg",
        "name": "first-page",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/indeterminate-radius-disabled.svg",
        "name": "indeterminate-radius-disabled",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/indeterminate-radius.svg",
        "name": "indeterminate-radius",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/landline.svg",
        "name": "landline",
        "dimensions": {
          "width": 16,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/last-page.svg",
        "name": "last-page",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/lead.svg",
        "name": "lead",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/logout.svg",
        "name": "logout",
        "dimensions": {
          "width": 16,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/minus.svg",
        "name": "minus",
        "dimensions": {
          "width": 13,
          "height": 13
        }
      },
      {
        "path": "assets/icons/16px/more-info.svg",
        "name": "more-info",
        "dimensions": {
          "width": 17,
          "height": 4
        }
      },
      {
        "path": "assets/icons/16px/next-page.svg",
        "name": "next-page",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/organization-node-setting-fill.svg",
        "name": "organization-node-setting-fill",
        "dimensions": {
          "width": 14,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/pass.svg",
        "name": "pass",
        "dimensions": {
          "width": 10,
          "height": 10
        }
      },
      {
        "path": "assets/icons/16px/pen.svg",
        "name": "pen",
        "dimensions": {
          "width": 17,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/pencil.svg",
        "name": "pencil",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/phone.svg",
        "name": "phone",
        "dimensions": {
          "width": 16,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/plus-2.5px.svg",
        "name": "plus-2.5px",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/plus.svg",
        "name": "plus",
        "dimensions": {
          "width": 13,
          "height": 13
        }
      },
      {
        "path": "assets/icons/16px/previous-page.svg",
        "name": "previous-page",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/question-circle.svg",
        "name": "question-circle",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/redo.svg",
        "name": "redo",
        "dimensions": {
          "width": 17,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/remove.svg",
        "name": "remove",
        "dimensions": {
          "width": 15,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/salesforce.svg",
        "name": "salesforce",
        "dimensions": {
          "width": 25,
          "height": 18
        }
      },
      {
        "path": "assets/icons/16px/search.svg",
        "name": "search",
        "dimensions": {
          "width": 12,
          "height": 13
        }
      },
      {
        "path": "assets/icons/16px/send.svg",
        "name": "send",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/sorting.svg",
        "name": "sorting",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/sync.svg",
        "name": "sync",
        "dimensions": {
          "width": 17,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/tick-2.svg",
        "name": "tick-2",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/translate.svg",
        "name": "translate",
        "dimensions": {
          "width": 16,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/unchecked-radius-disabled.svg",
        "name": "unchecked-radius-disabled",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/unchecked-radius.svg",
        "name": "unchecked-radius",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/unchecked.svg",
        "name": "unchecked",
        "dimensions": {
          "width": 14,
          "height": 14
        }
      },
      {
        "path": "assets/icons/16px/upload.svg",
        "name": "upload",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/view-more.svg",
        "name": "view-more",
        "dimensions": {
          "width": 17,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/view.svg",
        "name": "view",
        "dimensions": {
          "width": 17,
          "height": 16
        }
      }
    ]
  },
  {
    "size": "24px",
    "icons": [
      {
        "path": "assets/icons/24px/add.svg",
        "name": "add",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/application-form.svg",
        "name": "application-form",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/back.svg",
        "name": "back",
        "dimensions": {
          "width": 23,
          "height": 34
        }
      },
      {
        "path": "assets/icons/24px/bank.svg",
        "name": "bank",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/bind-company.svg",
        "name": "bind-company",
        "dimensions": {
          "width": 25,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/bind.svg",
        "name": "bind",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/24px/calendar.svg",
        "name": "calendar",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/camera.svg",
        "name": "camera",
        "dimensions": {
          "width": 25,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/cash.svg",
        "name": "cash",
        "dimensions": {
          "width": 25,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chervron-background-dark.svg",
        "name": "chervron-background-dark",
        "dimensions": {
          "width": 31,
          "height": 30
        }
      },
      {
        "path": "assets/icons/24px/chervron-background-light.svg",
        "name": "chervron-background-light",
        "dimensions": {
          "width": 31,
          "height": 31
        }
      },
      {
        "path": "assets/icons/24px/chevron-down-disabled.svg",
        "name": "chevron-down-disabled",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-down-error.svg",
        "name": "chevron-down-error",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-down-warning.svg",
        "name": "chevron-down-warning",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-down.svg",
        "name": "chevron-down",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-left.svg",
        "name": "chevron-left",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-right.svg",
        "name": "chevron-right",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-up-error.svg",
        "name": "chevron-up-error",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-up-warning.svg",
        "name": "chevron-up-warning",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-up.svg",
        "name": "chevron-up",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/company.svg",
        "name": "company",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/contract.svg",
        "name": "contract",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/cross.svg",
        "name": "cross",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/customer.svg",
        "name": "customer",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/customerPriceForm.svg",
        "name": "customerPriceForm",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/24px/delivery-box.svg",
        "name": "delivery-box",
        "dimensions": {
          "width": 25,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/document-layout-left.svg",
        "name": "document-layout-left",
        "dimensions": {
          "width": 24,
          "height": 20
        }
      },
      {
        "path": "assets/icons/24px/download.svg",
        "name": "download",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/drag.svg",
        "name": "drag",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/edit-erp-customers.svg",
        "name": "edit-erp-customers",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/erp.svg",
        "name": "erp",
        "dimensions": {
          "width": 23,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/error-2.svg",
        "name": "error-2",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/error-circle-red.svg",
        "name": "error-circle-red",
        "dimensions": {
          "width": 27,
          "height": 27
        }
      },
      {
        "path": "assets/icons/24px/error.svg",
        "name": "error",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/export.svg",
        "name": "export",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/filter.svg",
        "name": "filter",
        "dimensions": {
          "width": 25,
          "height": 25
        }
      },
      {
        "path": "assets/icons/24px/fit-to-screen.svg",
        "name": "fit-to-screen",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/fx.svg",
        "name": "fx",
        "dimensions": {
          "width": 26,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/gear.svg",
        "name": "gear",
        "dimensions": {
          "width": 26,
          "height": 26
        }
      },
      {
        "path": "assets/icons/24px/gears.svg",
        "name": "gears",
        "dimensions": {
          "width": 26,
          "height": 21
        }
      },
      {
        "path": "assets/icons/24px/globe.svg",
        "name": "globe",
        "dimensions": {
          "width": 25,
          "height": 25
        }
      },
      {
        "path": "assets/icons/24px/import.svg",
        "name": "import",
        "dimensions": {
          "width": 25,
          "height": 25
        }
      },
      {
        "path": "assets/icons/24px/info-1.svg",
        "name": "info-1",
        "dimensions": {
          "width": 25,
          "height": 25
        }
      },
      {
        "path": "assets/icons/24px/info-2.svg",
        "name": "info-2",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/info-3.svg",
        "name": "info-3",
        "dimensions": {
          "width": 12,
          "height": 12
        }
      },
      {
        "path": "assets/icons/24px/key.svg",
        "name": "key",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/list.svg",
        "name": "list",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/location.svg",
        "name": "location",
        "dimensions": {
          "width": 26,
          "height": 30
        }
      },
      {
        "path": "assets/icons/24px/mail.svg",
        "name": "mail",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/mobile.svg",
        "name": "mobile",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/notes.svg",
        "name": "notes",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/notification-customer.svg",
        "name": "notification-customer",
        "dimensions": {
          "width": 33,
          "height": 33
        }
      },
      {
        "path": "assets/icons/24px/notification-order-delivery.svg",
        "name": "notification-order-delivery",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/notification-product.svg",
        "name": "notification-product",
        "dimensions": {
          "width": 33,
          "height": 33
        }
      },
      {
        "path": "assets/icons/24px/notification-sales.svg",
        "name": "notification-sales",
        "dimensions": {
          "width": 33,
          "height": 33
        }
      },
      {
        "path": "assets/icons/24px/notification.svg",
        "name": "notification",
        "dimensions": {
          "width": 22,
          "height": 25
        }
      },
      {
        "path": "assets/icons/24px/office.svg",
        "name": "office",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/order-delivery-details.svg",
        "name": "order-delivery-details",
        "dimensions": {
          "width": 25,
          "height": 25
        }
      },
      {
        "path": "assets/icons/24px/order-delivery-form.svg",
        "name": "order-delivery-form",
        "dimensions": {
          "width": 39,
          "height": 39
        }
      },
      {
        "path": "assets/icons/24px/order-delivery-record.svg",
        "name": "order-delivery-record",
        "dimensions": {
          "width": 39,
          "height": 39
        }
      },
      {
        "path": "assets/icons/24px/order-detail.svg",
        "name": "order-detail",
        "dimensions": {
          "width": 24,
          "height": 25
        }
      },
      {
        "path": "assets/icons/24px/order.svg",
        "name": "order",
        "dimensions": {
          "width": 32,
          "height": 39
        }
      },
      {
        "path": "assets/icons/24px/organization-node-setting.svg",
        "name": "organization-node-setting",
        "dimensions": {
          "width": 21,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/organization.svg",
        "name": "organization",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/others.svg",
        "name": "others",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/payment-record.svg",
        "name": "payment-record",
        "dimensions": {
          "width": 38,
          "height": 38
        }
      },
      {
        "path": "assets/icons/24px/payment.svg",
        "name": "payment",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/percent.svg",
        "name": "percent",
        "dimensions": {
          "width": 22,
          "height": 23
        }
      },
      {
        "path": "assets/icons/24px/person-1.svg",
        "name": "person-1",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/person-2.svg",
        "name": "person-2",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/person-3.svg",
        "name": "person-3",
        "dimensions": {
          "width": 25,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/person-4.svg",
        "name": "person-4",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/picture-frame.svg",
        "name": "picture-frame",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/price-form.svg",
        "name": "price-form",
        "dimensions": {
          "width": 25,
          "height": 25
        }
      },
      {
        "path": "assets/icons/24px/price-item.svg",
        "name": "price-item",
        "dimensions": {
          "width": 25,
          "height": 25
        }
      },
      {
        "path": "assets/icons/24px/print.svg",
        "name": "print",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/product-catalogue.svg",
        "name": "product-catalogue",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/product.svg",
        "name": "product",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/quotation.svg",
        "name": "quotation",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/reload.svg",
        "name": "reload",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/sales.svg",
        "name": "sales",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/side-arrow-left.svg",
        "name": "side-arrow-left",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/side-arrow-right-small.svg",
        "name": "side-arrow-right-small",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/side-arrow-right.svg",
        "name": "side-arrow-right",
        "dimensions": {
          "width": 25,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/spare-part.svg",
        "name": "spare-part",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/tag.svg",
        "name": "tag",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/tick.svg",
        "name": "tick",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/unlink.svg",
        "name": "unlink",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/user-blue.svg",
        "name": "user-blue",
        "dimensions": {
          "width": 27,
          "height": 31
        }
      },
      {
        "path": "assets/icons/24px/van.svg",
        "name": "van",
        "dimensions": {
          "width": 25,
          "height": 25
        }
      },
      {
        "path": "assets/icons/24px/visibility-off.svg",
        "name": "visibility-off",
        "dimensions": {
          "width": 29,
          "height": 29
        }
      },
      {
        "path": "assets/icons/24px/visibility-on.svg",
        "name": "visibility-on",
        "dimensions": {
          "width": 29,
          "height": 29
        }
      },
      {
        "path": "assets/icons/24px/zoom-in.svg",
        "name": "zoom-in",
        "dimensions": {
          "width": 23,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/zoom-out.svg",
        "name": "zoom-out",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      }
    ]
  },
  {
    "size": "40px",
    "icons": [
      {
        "path": "assets/icons/40px/file-docx.svg",
        "name": "file-docx",
        "dimensions": {
          "width": 40,
          "height": 41
        }
      },
      {
        "path": "assets/icons/40px/file-pdf.svg",
        "name": "file-pdf",
        "dimensions": {
          "width": 40,
          "height": 41
        }
      },
      {
        "path": "assets/icons/40px/file-xlsx.svg",
        "name": "file-xlsx",
        "dimensions": {
          "width": 40,
          "height": 41
        }
      }
    ]
  },
  {
    "size": "illustration",
    "icons": [
      {
        "path": "assets/icons/illustration/no-data.svg",
        "name": "no-data",
        "dimensions": {
          "width": 97,
          "height": 96
        }
      },
      {
        "path": "assets/icons/illustration/triangle.svg",
        "name": "triangle",
        "dimensions": {
          "width": 37,
          "height": 22
        }
      }
    ]
  }
];
