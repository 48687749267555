import {HttpStatusCode} from '@angular/common/http';
import {Component} from '@angular/core';

@Component({
  template: '<app-status-code-template [httpStatusCode]="statusCode" [localizedName]="name"></app-status-code-template>'
})
export class UnauthorizedPageComponent {
  statusCode = HttpStatusCode.Unauthorized;
  name = 'UNAUTHORIZED';
}
