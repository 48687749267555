import {Component, ElementRef, ViewChild} from '@angular/core';
import {GridNumberInputCellRendererParams} from './grid-number-input-cell-renderer-params';
import {AgRendererComponent} from 'ag-grid-angular';
import {isNil} from 'lodash-es';

@Component({
  templateUrl: './grid-number-input.component.html'
})
export class GridNumberInputComponent implements AgRendererComponent {
  @ViewChild('inputElement') public inputElement: ElementRef;
  public updateValue: (rowData, value: number) => void;
  public minValue: number;
  private value: number;
  private canShowInput: (rowData) => boolean;
  private rowData: any;

  public agInit(params: GridNumberInputCellRendererParams): void {
    this.canShowInput = params.canShowInput;
    this.value = params.value;
    this.updateValue = params.updateValue;
    this.minValue = params.minValue || null;
    this.rowData = params.node.data;
  }

  public refresh(params: any): boolean {
    return false;
  }

  public canShowInputElement(): boolean {
    return !isNil(this.canShowInput) && this.canShowInput(this.rowData);
  }

  public getValue(): string {
    return `${this.value}`;
  }

  public setValue(value: string): void {
    if (value) {
      let valueToSet = Number(value);
      if (this.minValue && valueToSet < this.minValue) {
        valueToSet = this.minValue;
      }
      this.updateValue(this.rowData, valueToSet);
      this.inputElement.nativeElement.value = valueToSet;
      this.value = valueToSet;
    } else {
      this.updateValue(this.rowData, null);
      this.inputElement.nativeElement.value = value;
    }
  }
}
