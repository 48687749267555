import {Component} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import {GridApi, ICellRendererParams} from 'ag-grid-community';
import {isNil} from 'lodash-es';

@Component({
  template: ` <mat-checkbox [disabled]="!isEnabled || !hasSyncPermission" [indeterminate]="indeterminate" [checked]="checked" (change)="onCheckboxChange($event)"></mat-checkbox> `,
  styles: [
    `
      :host ::ng-deep {
        height: auto;

        .mat-checkbox-disabled .mat-checkbox-background {
          background-color: #e6e6e6 !important;
          border: 1px solid #ccc !important;
        }

        .mat-checkbox-disabled .mat-checkbox-checkmark {
          fill: #ccc !important;
        }
      }
    `
  ]
})
export class GridCheckBoxComponent implements AgRendererComponent {
  public checked = false;
  public isEnabled = true;
  public indeterminate = false;
  public hasSyncPermission = true;

  private isHeaderCheckBox: boolean;
  private gridApi: GridApi;
  private node: any;

  public refresh;

  agInit(params: ICellRendererParams): void {
    this.isHeaderCheckBox = isNil(params.data);
    this.hasSyncPermission = params.context?.hasSyncPermission ?? true;

    this.gridApi = params.api;
    if (!this.isHeaderCheckBox) {
      this.node = this.gridApi.getRowNode(params.rowIndex.toString());
      this.checked = this.node.isSelected();
      this.isEnabled = params.data.isSyncEnabled && !params.data.unavailable;
    } else {
      const renderedNodes = this.gridApi.getRenderedNodes().filter((f) => !isNil(f.data));
      const allNodesSelected = renderedNodes.filter((f) => f.data.isSyncEnabled && !f.data.unavailable).every((node) => node.isSelected());
      this.checked = allNodesSelected && renderedNodes.length > 0;
      if (this.checked) {
        this.indeterminate = false;
      } else {
        this.indeterminate = !renderedNodes.filter((f) => f.data.isSyncEnabled && !f.data.unavailable).every((node) => !node.isSelected());
      }
    }
  }

  onCheckboxChange(event: any): void {
    if (this.isHeaderCheckBox) {
      let nodes = this.gridApi.getRenderedNodes().filter((f) => !isNil(f.data) && f.data.isSyncEnabled && !f.data.unavailable);
      nodes.forEach((x) => x.setSelected(event.checked));
      this.gridApi.redrawRows({rowNodes: nodes});
      this.gridApi.refreshHeader();
    }
  }
}
