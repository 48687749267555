import {Directive, ElementRef, HostListener, Input, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';
import {LocaleUtils} from '@application/helper/locale-utils';
import {MimeType} from '@application/helper/mime-type.enum';
import {L10nIntlService} from 'angular-l10n';
import {isEmpty, isEqual, isNil, isNumber, join, last, size, split} from 'lodash-es';

@Directive({
  selector: 'input[appL10nDecimal]'
})
export class InputL10nDecimalDirective {
  @Input() public decimals = false;
  @Input() public negativeNumbers = false;
  @Input() public maximumFractionDigits = 13;

  private elementRef: ElementRef;
  private readonly l10nIntlService: L10nIntlService;
  private readonly ngControl: NgControl;

  public constructor(elementRef: ElementRef, l10nIntlService: L10nIntlService, @Optional() ngControl: NgControl) {
    this.elementRef = elementRef;
    this.l10nIntlService = l10nIntlService;
    this.ngControl = ngControl;
  }

  @HostListener('ngModelChange')
  public onNgModelChange(): void {
    const currentValue: string = this.getNormalizedText(this.getCurrentTextInput());

    if (!this.shouldSkipFormatting(currentValue)) {
      let value: number = LocaleUtils.parseNumber(currentValue, this.l10nIntlService);

      if (isNumber(value)) {
        if (!this.negativeNumbers && value < 0) {
          value = 0;
        }
        const formattedValue = LocaleUtils.formatNumber(value, this.l10nIntlService, this.getMaximumFractionDigits());

        this.elementRef.nativeElement.value = formattedValue;

        if (!isNil(this.ngControl)) {
          this.ngControl.control.patchValue(formattedValue, {emitEvent: false, emitViewToModelChange: false});
        }
      }
    }
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(keyboardEvent: KeyboardEvent): void {
    if (!this.isNumericKeyCode(keyboardEvent.key) && !this.isCommand(keyboardEvent) && !this.allowNegativeNumbers(keyboardEvent.key) && !this.allowDecimals(keyboardEvent.key)) {
      keyboardEvent.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData(MimeType.PLAIN_TEXT);
    this.elementRef.nativeElement.value = this.getProcessedText(pastedInput);
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any): void {
    event.preventDefault();
    const droppedInput: string = event.dataTransfer.getData(MimeType.TEXT).replace(/\D/g, '');
    this.elementRef.nativeElement.value = this.getProcessedText(droppedInput);
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
    this.elementRef.nativeElement.focus();
  }

  private getMaximumFractionDigits(): number {
    return this.decimals ? this.maximumFractionDigits : 0;
  }

  private isNumericKeyCode(key: string): boolean {
    return /^\d$/.test(key);
  }

  private isCommand(keyboardEvent: KeyboardEvent): boolean {
    return this.isControlCommand(keyboardEvent) || this.isStandardCommand(keyboardEvent.key);
  }

  private isControlCommand(keyboardEvent: KeyboardEvent): boolean {
    return /^[acvxyz]$/.test(keyboardEvent.key) && (keyboardEvent.ctrlKey || keyboardEvent.metaKey);
  }

  private isStandardCommand(key: string): boolean {
    const standardCommands: string[] = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Shift', 'Home', 'End'];
    return new RegExp(`^(${join(standardCommands, '|')})$`).test(key);
  }

  private allowDecimals(key: string): boolean {
    return this.isDecimalSeparator(key) && this.decimals && !new RegExp(`[${this.getDecimalSeparator()}]`).test(this.getCurrentTextInput());
  }

  private isDecimalSeparator(key: string): boolean {
    return isEqual(key, this.getDecimalSeparator());
  }

  private getDecimalSeparator(): string {
    return LocaleUtils.getDecimalSeparator(this.l10nIntlService);
  }

  private allowNegativeNumbers(key: string): boolean {
    return isEqual('-', key) && this.negativeNumbers;
  }

  private getCurrentTextInput(): string {
    let input = this.elementRef.nativeElement.value;
    if (isNumber(input)) {
      input = LocaleUtils.formatNumber(input, this.l10nIntlService, this.maximumFractionDigits);
    }
    return `${input}`;
  }

  private getProcessedText(rawText: string): string {
    let processedText: string;
    const forbiddenCharacters = new RegExp(`[^0-9${this.getDecimalSeparator()}-]`, 'g');
    processedText = rawText.replace(forbiddenCharacters, '');

    if (!this.decimals) {
      const decimalSeparators = new RegExp(`[${this.getDecimalSeparator()}]`, 'g');
      processedText = processedText.replace(decimalSeparators, '');
    }

    if (!this.negativeNumbers) {
      processedText = processedText.replace(/[-]/g, '');
    }

    return processedText;
  }

  private getNormalizedText(rawText: string): string {
    if (!isEmpty(rawText)) {
      rawText = rawText.charAt(0).concat(rawText.slice(1).replace(/[-]/g, '')).replace(/[.,]/g, this.getDecimalSeparator());
    }
    return rawText;
  }

  private shouldSkipFormatting(text: string): boolean {
    let result = true;
    const decimalSeparator = this.getDecimalSeparator();

    if (new RegExp(`[${this.getDecimalSeparator()}]`).test(text)) {
      const decimalValue: string = last(split(text, decimalSeparator));
      const lastChar = text.charAt(size(text) - 1);
      result = this.isDecimalSeparator(lastChar) || (isEqual(lastChar, '0') && size(decimalValue) <= this.getMaximumFractionDigits());
    }

    return result;
  }
}
