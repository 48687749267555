export enum SalesVerificationStatus {
  DRAFT = 1,
  PENDING_SEND_TO_BPM,
  PENDING_BPM_APPROVAL,
  APPROVED,
  REVERIFY,
  REJECTED,
  NONE
}

export const SALES_VERIFICATION_VERIFIED_STATUSES = [SalesVerificationStatus.APPROVED, SalesVerificationStatus.REJECTED, SalesVerificationStatus.REVERIFY];

export const SALES_VERIFICATION_EDITABLE_STATUSES = [SalesVerificationStatus.DRAFT, SalesVerificationStatus.REVERIFY, SalesVerificationStatus.NONE];

export const SALES_VERIFICATION_SHOW_REMARK_STATUSES = [SalesVerificationStatus.REJECTED, SalesVerificationStatus.REVERIFY];

export const SALES_VERIFICATION_MANUALLY_CREATE = [SalesVerificationStatus.DRAFT, SalesVerificationStatus.PENDING_SEND_TO_BPM, SalesVerificationStatus.REVERIFY];

export const SALES_VERIFICATION_SENDABLE_TO_BPM_STATUSES = [SalesVerificationStatus.DRAFT, SalesVerificationStatus.PENDING_SEND_TO_BPM, SalesVerificationStatus.REVERIFY];
