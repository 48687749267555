<button
  mat-flat-button
  [tabIndex]="tabIndex"
  *ngIf="isRaisedButton()"
  color="primary"
  type="button"
  disabled
  [disabled]="isDisabled()"
  [class.disabled]="isInProgress() || isInCountingDown()"
  [style.width]="getButtonWidth()"
  (click)="startProgress()"
  [ngClass]="{
    'ng-invalid': invalid,
    invalid: invalid
  }"
>
  <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</button>

<button
  mat-stroked-button
  [tabIndex]="tabIndex"
  *ngIf="!isRaisedButton()"
  type="button"
  [disabled]="isDisabled()"
  [class.disabled]="isInProgress() || isInCountingDown()"
  [style.width]="getButtonWidth()"
  (click)="startProgress()"
  [ngClass]="{
    'ng-invalid': invalid,
    invalid: invalid
  }"
>
  <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</button>

<ng-template #btnContent>
  <mat-spinner diameter="22" *ngIf="isInProgress()" mode="indeterminate"></mat-spinner>
  <span class="button-text">
    {{ getLabel() | translate: labelReplaceableValues }}
  </span>
  <span class="countdown-timer">
    <countdown #timer (event)="enableButton($event)" [hidden]="!isInCountingDown()" [config]="timerConfig"></countdown>
  </span>
</ng-template>
