<div fxLayout="column" fxFlex>
  <div class="mat-dialog-header" fxLayout="column" fxLayoutAlign="center center">
    <h2 mat-dialog-title>{{ title }}</h2>
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>
  <div fxLayout="row" class="status">
    <div [class]="status | statusClass: statusType"></div>
    <input class="status-title" matInput [value]="status | statusTitle: statusType" readonly />
  </div>
  <mat-dialog-content class="remark">
    <div style="word-wrap: break-word">
      {{ remark | dataAvailability }}
    </div>
  </mat-dialog-content>
</div>
