import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-template-base',
  templateUrl: './template-base.component.html',
  styleUrls: ['./template-base.component.scss']
})
export class TemplateBaseComponent {
  @Input() public showLogo = false;
}
