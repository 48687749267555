import {Component, Inject, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AlertDialogUtils} from '@application/helper/alert-dialog-utils';
import {includes, isEqual, isNil} from 'lodash-es';
import {AlertDialogConfirmationType, AlertDialogData, AlertDialogResult} from './alert-dialog-data';

@Component({
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  @ViewChild('container', {read: ViewContainerRef, static: true}) private container: ViewContainerRef;
  public dialogData: AlertDialogData;
  private dialogRef: MatDialogRef<AlertDialogComponent>;

  public constructor(dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.dialogRef = dialogRef;
    this.dialogData = data;
  }

  ngOnInit(): void {
    if (this.hasExtraComponent()) {
      if (this.dialogData.extraComponent instanceof TemplateRef) {
        this.container.createEmbeddedView(<TemplateRef<any>>this.dialogData.extraComponent);
      } else {
        this.container.element.nativeElement.innerHTML = this.dialogData.extraComponent;
      }
    }
  }

  public cancel(): void {
    this.dialogRef.close(AlertDialogResult.CANCEL);
  }

  public report(): void {
    this.dialogRef.close();
  }

  public hasCancelButton(): boolean {
    return !isNil(this.dialogData) && this.hasCancelText();
  }

  public hasReportButton(): boolean {
    return !isNil(this.dialogData) && this.hasConfirmationTypeReport();
  }

  public hasConfirmButton(): boolean {
    return !isNil(this.dialogData) && this.hasConfirmationTypeConfirm();
  }

  public hasDeleteButton(): boolean {
    return !isNil(this.dialogData) && this.hasConfirmationTypeDelete();
  }

  public getConfirmButtonText(): string {
    let result = '';
    if (!isNil(this.dialogData)) {
      result = isNil(this.dialogData.confirmButtonText) ? AlertDialogUtils.getButtonTextForConfirmationType(AlertDialogConfirmationType.CONFIRM) : this.dialogData.confirmButtonText;
    }

    return result;
  }

  public delete(): void {
    this.dialogRef.close(AlertDialogResult.DELETE);
  }

  public confirm(): void {
    this.dialogRef.close(AlertDialogResult.CONFIRM);
  }

  public hasExtraInformationText(): boolean {
    return !isNil(this.dialogData.extraInformationText);
  }

  public hasExtraComponent(): boolean {
    return !isNil(this.dialogData.extraComponent);
  }

  private hasConfirmationTypeConfirm(): boolean {
    return includes(this.dialogData.confirmationTypes, AlertDialogConfirmationType.CONFIRM);
  }

  private hasConfirmationTypeDelete(): boolean {
    return includes(this.dialogData.confirmationTypes, AlertDialogConfirmationType.DELETE);
  }

  private hasConfirmationTypeReport(): boolean {
    return includes(this.dialogData.confirmationTypes, AlertDialogConfirmationType.REPORT);
  }

  private hasCancelText(): boolean {
    return !isEqual(this.dialogData.cancelButtonText, '');
  }
}
