import {Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ComponentRef, Directive, ElementRef, HostListener, Input, OnInit, TemplateRef} from '@angular/core';
import {TooltipComponent} from '@shared/components/tooltip/tooltip.component';

@Directive({selector: '[appTooltip]'})
export class TooltipDirective implements OnInit {
  @Input('appTooltip') content: string | TemplateRef<any>;
  private overlayRef: OverlayRef;
  private overlayPositionBuilder: OverlayPositionBuilder;
  private elementRef: ElementRef;
  private overlay: Overlay;

  constructor(overlayPositionBuilder: OverlayPositionBuilder, elementRef: ElementRef, overlay: Overlay) {
    this.overlayPositionBuilder = overlayPositionBuilder;
    this.elementRef = elementRef;
    this.overlay = overlay;
  }
  @HostListener('mouseover')
  public show(): void {
    const tooltipPortal = new ComponentPortal(TooltipComponent);

    const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(tooltipPortal);

    if (typeof this.content === 'string') {
      tooltipRef.instance.text = this.content;
    } else {
      tooltipRef.instance.content = this.content;
    }
  }

  @HostListener('mouseout')
  public hide(): void {
    this.overlayRef.detach();
  }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetX: -45
      }
    ]);

    this.overlayRef = this.overlay.create({positionStrategy});
  }
}
