import {Component, EventEmitter, Input, Output} from '@angular/core';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-vdw-card',
  templateUrl: './vdw-card.component.html',
  styleUrls: ['./vdw-card.component.scss']
})
export class VdwCardComponent {
  @Input() public titleIcon: string;
  @Input() public title: string;
  @Input() public canShowButton = true;
  @Input() public buttonLabel = 'GENERAL.ACTIONS.SETTING';

  @Output() public clickButtonEvent: EventEmitter<any> = new EventEmitter<void>();
}
