<div
  class="ag-cell-label-container ag-header-cell-sorted-asc"
  (click)="onOwnSortRequested($event)"
  [ngStyle]="{'ag-header-cell-sorted-asc': sorted === 'asc', 'ag-header-cell-sorted-desc': sorted === 'desc', 'ag-header-cell-sorted-none': sorted === ''}"
  role="presentation"
  [matTooltip]="params.displayName | translate: {count: 1}"
  [matTooltipDisabled]="!showTooltip"
  [matTooltipHideDelay]="tooltipHideDelayMs"
  [matTooltipShowDelay]="tooltipShowDelayMs"
>
  <div ref="eLabel" class="ag-header-cell-label" role="presentation">
    <span #headerText ref="eText" class="ag-header-cell-text" role="columnheader">
      {{ params.displayName | translate: {count: 1} }}
    </span>
    <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden" aria-hidden="true"> </span>
    <span fxFlex="16px" fxLayout="column wrap" class="sorting-arrows" *ngIf="isSortingEnabled()">
      <mat-icon class="sorting" [class.sorting-asc]="isSortingDirectionAscending()" [class.sorting-desc]="isSortingDirectionDescending()" svgIcon="sorting"></mat-icon>
    </span>
    <span ref="eFilter" class="ag-header-icon ag-filter-icon ag-hidden" aria-hidden="true">
      <svg width="10" height="10">
        <polygon points="0,0 4,4 4,10 6,10 6,4 10,0" class="ag-header-icon"></polygon>
      </svg>
    </span>
  </div>
</div>
