import {some} from 'lodash-es';
import {AuthenticationRoute} from './authentication-route';
import {ComponentsRoute} from './components-route';
import {CustomerRelationshipRoute} from './customer-relationship/customer-relationship-route';
import {DashboardRoute} from './dashboard-route';
import {ErpRoute} from './erp/erp-route';
import {BroadcastMessageRoute} from './message-centre/broadcast-message-route';
import {NotificationRoute} from './notification/notification.route';
import {OrderDeliveryFormRoute} from './order-delivery/order-delivery-form-route';
import {OrganizationRoute} from './organization/organization-route';
import {ProductCatalogueRoute} from './product-catalogue/product-catalogue-route';
import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';
import {RouteNode} from './route-node';
import {SalesRoute} from './sales/sales-route';

export class RootRoute extends RouteBranch {
  private _unauthorized: RouteLeaf;
  private _forbidden: RouteLeaf;
  private _notFound: RouteLeaf;
  private _profile: RouteLeaf;
  private _components: ComponentsRoute;
  private _authentication: AuthenticationRoute;
  private _dashboard: DashboardRoute;
  private _customSetting: RouteLeaf;
  private _organization: OrganizationRoute;
  private _customerRelationship: CustomerRelationshipRoute;
  private _productCatalogue: ProductCatalogueRoute;
  private _sales: SalesRoute;
  private _broadcastMessage: BroadcastMessageRoute;
  private _notification: NotificationRoute;
  private _orderDelivery: OrderDeliveryFormRoute;
  private _erp: ErpRoute;
  private _salesforce: RouteLeaf;

  public get unauthorized(): RouteLeaf {
    return this._unauthorized;
  }

  public set unauthorized(value: RouteLeaf) {
    this.replace(this._unauthorized, value);
    this._unauthorized = value;
  }

  public get forbidden(): RouteLeaf {
    return this._forbidden;
  }

  public set forbidden(value: RouteLeaf) {
    this.replace(this._forbidden, value);
    this._forbidden = value;
  }

  public get notFound(): RouteLeaf {
    return this._notFound;
  }

  public set notFound(value: RouteLeaf) {
    this.replace(this._notFound, value);
    this._notFound = value;
  }

  public get profile(): RouteLeaf {
    return this._profile;
  }

  public set profile(value: RouteLeaf) {
    this.replace(this._profile, value);
    this._profile = value;
  }

  public get customSetting(): RouteLeaf {
    return this._customSetting;
  }

  public set customSetting(value: RouteLeaf) {
    this.replace(this._customSetting, value);
    this._customSetting = value;
  }

  public get authentication(): AuthenticationRoute {
    return this._authentication;
  }

  public set authentication(value: AuthenticationRoute) {
    this.replace(this._authentication, value);
    this._authentication = value;
  }

  public get dashboard(): DashboardRoute {
    return this._dashboard;
  }

  public set dashboard(value: DashboardRoute) {
    this.replace(this._dashboard, value);
    this._dashboard = value;
  }

  public get components(): ComponentsRoute {
    return this._components;
  }

  public set components(value: ComponentsRoute) {
    this.replace(this._components, value);
    this._components = value;
  }

  public get organization(): OrganizationRoute {
    return this._organization;
  }

  public set organization(value: OrganizationRoute) {
    this.replace(this._organization, value);
    this._organization = value;
  }

  public get customerRelationship(): CustomerRelationshipRoute {
    return this._customerRelationship;
  }

  public set customerRelationship(value: CustomerRelationshipRoute) {
    this.replace(this._customerRelationship, value);
    this._customerRelationship = value;
  }

  public get productCatalogue(): ProductCatalogueRoute {
    return this._productCatalogue;
  }

  public set productCatalogue(value: ProductCatalogueRoute) {
    this.replace(this._productCatalogue, value);
    this._productCatalogue = value;
  }

  public get sales(): SalesRoute {
    return this._sales;
  }

  public set sales(value: SalesRoute) {
    this.replace(this._sales, value);
    this._sales = value;
  }

  public get messageCentre(): BroadcastMessageRoute {
    return this._broadcastMessage;
  }

  public set messageCentre(value: BroadcastMessageRoute) {
    this.replace(this._broadcastMessage, value);
    this._broadcastMessage = value;
  }

  public get notification(): NotificationRoute {
    return this._notification;
  }

  public set notification(value: NotificationRoute) {
    this.replace(this._notification, value);
    this._notification = value;
  }

  public get orderDelivery(): OrderDeliveryFormRoute {
    return this._orderDelivery;
  }

  public set orderDelivery(value: OrderDeliveryFormRoute) {
    this.replace(this._orderDelivery, value);
    this._orderDelivery = value;
  }

  public get salesforce(): RouteLeaf {
    return this._salesforce;
  }

  public set salesforce(value: RouteLeaf) {
    this.replace(this._salesforce, value);
    this._salesforce = value;
  }

  public get erp(): ErpRoute {
    return this._erp;
  }

  public set erp(value: ErpRoute) {
    this.replace(this._erp, value);
    this._erp = value;
  }

  public isKnownPath(pathSegments: string[]): boolean {
    return some(this.getRoutes(), (routeNode: RouteNode) => {
      return routeNode.isKnownPath(pathSegments);
    });
  }
}
