import {Component} from '@angular/core';
import {MomentDatePipe} from '@application/pipes/moment-date.pipe';
import {TranslateService} from '@application/translation/translate.service';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {isEmpty, isNil} from 'lodash-es';

@Component({
  templateUrl: './date-time-renderer.component.html'
})
export class DateTimeRendererComponent implements ICellRendererAngularComp {
  public date?: Date;

  public constructor(private _translateService: TranslateService) {}

  public agInit(params: ICellRendererParams): void {
    this.date = isNil(params.value) || isEmpty(params.value) ? this._translateService.instant('GENERAL.NOT_AVAILABLE') : new MomentDatePipe().transform(params.value, 'YYYY-MM-DD');
  }

  public refresh(params: any): boolean {
    return false;
  }
}
