<div class="status-bar" [ngClass]="statusBarClass"></div>

<div fxLayout="row" [fxLayoutAlign]="canShowActionButton() ? 'space-between top' : 'space-between center'" fxLayoutGap="16px">
  <mat-icon [svgIcon]="titleIcon" class="message-icon"></mat-icon>

  <div class="content" fxFlex="100%">
    <div class="title" *ngIf="title">{{ title }}</div>
    <div class="description" [innerHTML]="message"></div>
    <div class="toolbar" *ngIf="canShowActionButton()">
      <button mat-flat-button (click)="action()">{{ 'GENERAL.ACTIONS.VIEW' }}</button>
    </div>
  </div>

  <mat-icon svgIcon="cross" class="delete-icon" (click)="dismiss()"></mat-icon>
</div>
