import {Pipe, PipeTransform} from '@angular/core';
import {UserCodeName} from '@infrastructure/user-code-name';
import {isNil} from 'lodash-es';

@Pipe({
  name: 'userCodeName'
})
export class UserCodeNamePipe implements PipeTransform {
  public transform(value: UserCodeName): string {
    let result = null;

    if (!isNil(value?.code)) {
      result = value.code;

      if (!isNil(value?.name)) {
        result += ` - ${value.name}`;
      }
    }

    return result;
  }
}
