import {Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {NgControl} from '@angular/forms';
import {find, isEmpty, isEqual, isNil, size} from 'lodash-es';

@Directive({
  selector: 'input[appTime]'
})
export class InputTimeDirective implements OnInit {
  private control: NgControl;
  private readonly elementRef: ElementRef;
  private readonly renderer: Renderer2;
  private readonly backSpaceKey: string = 'Backspace';
  private readonly deleteKey: string = 'Delete';
  private readonly allowedKeys: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Delete', 'Backspace'];
  private readonly arrowsAndTab: string[] = ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Tab'];
  private readonly maxHoursAllowed = 23;
  private readonly maxMinutesAllowed = 59;

  public constructor(elementRef: ElementRef, renderer: Renderer2, control: NgControl) {
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.control = control;
  }

  @HostListener('focus', ['$event'])
  public onFocus(focusEvent: FocusEvent): void {
    const targetElement = <HTMLInputElement>focusEvent.target;
    targetElement.selectionStart = 0;
    targetElement.selectionEnd = 2;
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(keyboardEvent: KeyboardEvent): void {
    const targetElement = <HTMLInputElement>keyboardEvent.target;
    const arrowPressed = find(this.arrowsAndTab, (arrowOrTab) => isEqual(keyboardEvent.key, arrowOrTab));
    if (isNil(arrowPressed)) {
      this.preventDeletingColon(keyboardEvent, targetElement);
      this.preventHours(keyboardEvent, targetElement);
      this.preventMinutes(keyboardEvent, targetElement);
      this.preventOverflow(keyboardEvent, targetElement);
      this.allowOnlyIntegers(keyboardEvent);
    }
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(keyboardEvent: KeyboardEvent): void {
    const targetElement = <HTMLInputElement>keyboardEvent.target;
    const arrowPressed = find(this.arrowsAndTab, (arrowOrTab) => isEqual(keyboardEvent.key, arrowOrTab));
    if (isNil(arrowPressed)) {
      this.jumpFromHoursToMinutes(targetElement);
    }
  }

  @HostListener('dragstart', ['$event'])
  public onDragStart(dragEvent: DragEvent): void {
    dragEvent.preventDefault();
  }

  @HostListener('blur', ['$event'])
  public blur(blurEvent: FocusEvent): void {
    const targetElement = <HTMLInputElement>blurEvent.target;
    const targetValue = this.getFormattedHours(targetElement.value);
    this.formatMinutes(targetValue);
  }

  public ngOnInit(): void {
    if (this.inputHasNoValue()) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', '00:00');
    }
  }

  public inputHasNoValue(): boolean {
    return isEmpty(this.elementRef.nativeElement.value);
  }

  private preventDeletingColon(keyboardEvent: KeyboardEvent, targetElement: HTMLInputElement): void {
    let startValue = targetElement.selectionStart;
    const charIndexes = [];
    while (startValue < targetElement.selectionEnd) {
      charIndexes.push(startValue);
      startValue++;
    }

    if (isEqual(keyboardEvent.key, this.backSpaceKey) && isEmpty(charIndexes)) {
      charIndexes.push(startValue - 1);
    } else if (isEqual(keyboardEvent.key, this.deleteKey) && isEmpty(charIndexes)) {
      charIndexes.push(startValue);
    }
    const colon = find(charIndexes, (index) => isEqual(targetElement.value.charAt(index), ':'));
    if (!isNil(colon)) {
      keyboardEvent.preventDefault();
    }
  }

  private preventHours(keyboardEvent: KeyboardEvent, targetElement: HTMLInputElement): void {
    const key = keyboardEvent.key;
    const notSelectingBothNumberPositions = !isEqual(targetElement.selectionStart + 2, targetElement.selectionEnd);
    const isBiggerThen23LeftNumberPosition = isEqual(targetElement.selectionStart, 0) && Number(`${key}${targetElement.value.charAt(0)}`) > this.maxHoursAllowed;
    const isBiggerThen23RightNumberPosition = isEqual(targetElement.selectionStart, 1) && Number(`${targetElement.value.charAt(0)}${key}`) > this.maxHoursAllowed;
    if ((isBiggerThen23LeftNumberPosition || isBiggerThen23RightNumberPosition) && notSelectingBothNumberPositions) {
      keyboardEvent.preventDefault();
    }
  }

  private preventMinutes(keyboardEvent: KeyboardEvent, targetElement: HTMLInputElement): void {
    const colonIndex = targetElement.value.indexOf(':');
    const key = keyboardEvent.key;
    const notSelectingBothNumberPositions = !isEqual(targetElement.selectionStart + 2, targetElement.selectionEnd);
    const isBiggerThen59LeftNumberPosition = isEqual(targetElement.selectionStart, colonIndex + 1) && Number(`${key}${targetElement.value.charAt(colonIndex + 1)}`) > this.maxMinutesAllowed;
    const isBiggerThen59RightNumberPosition = isEqual(targetElement.selectionStart, colonIndex + 2) && Number(`${targetElement.value.charAt(colonIndex + 1)}${key}`) > this.maxMinutesAllowed;
    if ((isBiggerThen59LeftNumberPosition || isBiggerThen59RightNumberPosition) && notSelectingBothNumberPositions) {
      keyboardEvent.preventDefault();
    }
  }

  private preventOverflow(keyboardEvent: KeyboardEvent, targetElement: HTMLInputElement): void {
    const key = keyboardEvent.key;
    const colonIndex = targetElement.value.indexOf(':');
    const notSelecting = isEqual(targetElement.selectionStart, targetElement.selectionEnd);
    const selectingOnLeft = targetElement.selectionStart <= colonIndex;
    const selectingOnRight = targetElement.selectionStart > colonIndex;
    const isNotDeleting = !isEqual(key, this.backSpaceKey) && !isEqual(key, this.deleteKey);
    const isTooMuchTooTheRight = !isEmpty(targetElement.value.charAt(colonIndex + 2));
    if (notSelecting && isNotDeleting && ((colonIndex > 1 && selectingOnLeft) || (isTooMuchTooTheRight && selectingOnRight))) {
      keyboardEvent.preventDefault();
    }
  }

  private allowOnlyIntegers(keyboardEvent: KeyboardEvent): void {
    const isAllowed = find(this.allowedKeys, (key) => isEqual(keyboardEvent.key, key));

    if (isNil(isAllowed)) {
      keyboardEvent.preventDefault();
    }
  }

  private jumpFromHoursToMinutes(targetElement: HTMLInputElement) {
    if (isEqual(targetElement.selectionStart, 2)) {
      targetElement.selectionStart = 3;
      targetElement.selectionEnd = 5;
    }
  }

  private getFormattedHours(targetValue: string): string {
    const colon = ':';
    if (isEqual(targetValue.charAt(0), colon)) {
      targetValue = `00${targetValue}`;
      this.control.control.setValue(targetValue);
    } else if (isEqual(targetValue.charAt(1), colon)) {
      targetValue = `0${targetValue}`;
      this.control.control.setValue(targetValue);
    }

    return targetValue;
  }

  private formatMinutes(targetValue: string): void {
    const targetValueLength = size(targetValue);

    if (isEqual(targetValueLength, 3)) {
      this.control.control.setValue(`${targetValue}00`);
    } else if (isEqual(targetValueLength, 4)) {
      this.control.control.setValue(`${targetValue.substring(0, 3)}0${targetValue.substring(3)}`);
    }
  }
}
