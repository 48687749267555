<input
  #inputElement
  *ngIf="canShowInputElement()"
  matInput
  appL10nDecimal
  [attr.min]="minValue"
  type="number"
  [value]="getValue()"
  [placeholder]="'GENERAL.VALIDATION.MAXIMUM_LENGTH' | translate"
  (input)="setValue(inputElement.value)"
/>
