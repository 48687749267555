import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AlertDialogData, AlertDialogResult} from '../alert-dialog/alert-dialog-data';

@Component({
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent {
  public dialogData: AlertDialogData;
  private dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>;

  public constructor(dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.dialogRef = dialogRef;
    this.dialogData = data;
  }

  public cancel(): void {
    this.dialogRef.close(AlertDialogResult.CANCEL);
  }

  public confirm(): void {
    this.dialogRef.close(AlertDialogResult.CONFIRM);
  }
}
