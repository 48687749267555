import {Module} from '@domain/organization/role/module.enum';
import {Permission} from '@domain/organization/role/permission.enum';
import {first} from 'lodash-es';
import {RouteNode} from './route-node';

export class RouteLeaf extends RouteNode {
  public constructor(path: string, module?: Module, requiredPermission?: Permission, isMobileFriendly: boolean = false, isMobileOnly: boolean = false) {
    super(path, module, requiredPermission, isMobileFriendly, isMobileOnly);
  }

  public isKnownPath(pathSegments: string[]): boolean {
    return this.matchesPath(first(pathSegments));
  }
}
