import {AlertDialogConfirmationType} from 'src/app/presentation/components/alert-dialog/alert-dialog-data';

export class AlertDialogUtils {
  private static readonly CONFIRMATION_TYPE_TEXTS = new Map<AlertDialogConfirmationType, string>([
    [AlertDialogConfirmationType.CONFIRM, 'GENERAL.ACTIONS.CONFIRM'],
    [AlertDialogConfirmationType.DELETE, 'GENERAL.ACTIONS.DELETE']
  ]);

  public static getButtonTextForConfirmationType(confirmationType: AlertDialogConfirmationType): string {
    return this.CONFIRMATION_TYPE_TEXTS.get(confirmationType);
  }
}
