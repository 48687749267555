import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class NotificationService {
  private markAsReadSubject$: BehaviorSubject<number[]> = new BehaviorSubject([]);

  public getMarkAsReadObservable(): Observable<number[]> {
    return this.markAsReadSubject$.asObservable();
  }
  public setMarkAsReadSubject(data: number[]): void {
    this.markAsReadSubject$.next(data);
  }
}
