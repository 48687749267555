import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';

export const SIGNALR_SERVICE = new InjectionToken<ISignalRService>('ISignalRService');

export interface ISignalRService {
  startListener(methodName: string): void;
  startConnection(accessToken: string): void;
  getNotifications(): Observable<any>;
}
