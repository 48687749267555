import {isEmpty, isNil} from 'lodash-es';

export class ShortNameHelper {
  public static shortName(userName: string): string {
    let shortName = '';

    if (!isNil(userName) && !isEmpty(userName)) {
      const wordArr = userName.split(' ');
      const wordBase = wordArr.length > 1 ? wordArr.map((word: any) => word[0]).join('') : wordArr[0];
      shortName = wordBase.substring(0, 2).toUpperCase();
    }

    return shortName;
  }
}
