import {AddressType} from '@domain/customer/address-type.enum';
import {IdName} from '@infrastructure/id-name';
import {isNil} from 'lodash-es';
import {DefaultAddress} from '../custom-setting/address/default-address';

export class Address extends DefaultAddress {
  public readonly addressType: AddressType;

  public constructor(addressType: AddressType = null, country: IdName = null, street: string = null, postalCode: string = null, province: IdName = null, city: IdName = null, district: IdName = null) {
    super(country, street, postalCode, province, city, district);
    this.addressType = addressType;
  }

  public static fromJSON(data: any): Address {
    return isNil(data) ? null : Object.assign(new Address(), data);
  }

  public toJSON(): JSON {
    return {
      addressTypeId: Number(this.addressType),
      countryId: this.country?.id,
      provinceId: this.province?.id,
      cityId: this.city?.id,
      districtId: this.district?.id,
      street: this.street,
      postalCode: this.postalCode
    } as any as JSON;
  }
}
