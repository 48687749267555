import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@application/translation/translate.service';
import {NotificationModuleEnum} from '@domain/notification-module.enum';

@Pipe({
  name: 'notificationTitle'
})
export class NotificationTitlePipe implements PipeTransform {
  private readonly translateService: TranslateService;

  public constructor(translateService: TranslateService) {
    this.translateService = translateService;
  }

  public transform(module: NotificationModuleEnum): string {
    let title = '';
    switch (module) {
      case NotificationModuleEnum.CUSTOMER:
      case NotificationModuleEnum.REGISTRATION:
        title = this.translateService.instant('CRM.CATEGORY_TITLE');
        break;
      case NotificationModuleEnum.PRODUCT:
      case NotificationModuleEnum.SPARE_PART:
      case NotificationModuleEnum.PRICING:
        title = this.translateService.instant('PRODUCT_CATALOGUE.CATEGORY_TITLE');
        break;
      case NotificationModuleEnum.QUOTATION:
        title = this.translateService.instant('GENERAL.OBJECT_WITH_MANAGEMENT', {object: this.translateService.instant('SALES.QUOTATION.QUOTATION')});
        break;
      case NotificationModuleEnum.CONTRACT:
        title = this.translateService.instant('GENERAL.OBJECT_WITH_MANAGEMENT', {object: this.translateService.instant('SALES.CONTRACT.CONTRACT')});
        break;
      case NotificationModuleEnum.ORDER:
        title = this.translateService.instant('GENERAL.OBJECT_WITH_MANAGEMENT', {object: this.translateService.instant('SALES.ORDER.ORDER')});
        break;
      case NotificationModuleEnum.ORDER_DELIVERY_FORM:
        title = this.translateService.instant('ORDER_DELIVERY.CATEGORY_TITLE');
        break;
      case NotificationModuleEnum.PAYMENT:
        title = this.translateService.instant('SALES.CATEGORY_TITLE');
        break;
      default:
        title = '';
        break;
    }

    return title;
  }
}
