import {RouteUtils} from '@application/routing/route-utils';
import {NotificationModuleEnum} from '@domain/notification-module.enum';

export class NotificationDetailRoute {
  public static transform(module: NotificationModuleEnum): string {
    let route = '';
    switch (module) {
      case NotificationModuleEnum.CUSTOMER:
        route = RouteUtils.paths.customerRelationship.customer.detailsRoute.absolutePath;
        break;
      case NotificationModuleEnum.PRODUCT:
        route = RouteUtils.paths.productCatalogue.product.detailsRoute.absolutePath;
        break;
      case NotificationModuleEnum.QUOTATION:
        route = RouteUtils.paths.sales.quotation.detailsRoute.absolutePath;
        break;
      case NotificationModuleEnum.CONTRACT:
        route = RouteUtils.paths.sales.contract.detailsRoute.absolutePath;
        break;
      case NotificationModuleEnum.ORDER:
        route = RouteUtils.paths.sales.order.detailsRoute.absolutePath;
        break;
      case NotificationModuleEnum.SPARE_PART:
        route = RouteUtils.paths.productCatalogue.sparePart.detailsRoute.absolutePath;
        break;
      case NotificationModuleEnum.PRICING:
        route = RouteUtils.paths.productCatalogue.pricing.detailsRoute.absolutePath;
        break;
      case NotificationModuleEnum.REGISTRATION:
        route = RouteUtils.paths.customerRelationship.registration.detailsRoute.absolutePath;
        break;
      case NotificationModuleEnum.PAYMENT:
        route = RouteUtils.paths.sales.payment.detailsRoute.absolutePath;
        break;
      case NotificationModuleEnum.ORDER_DELIVERY_FORM:
        route = RouteUtils.paths.orderDelivery.detailsRoute.absolutePath;
        break;
      default:
        route = '';
        break;
    }

    return route;
  }
}
