import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class DashboardRoute extends RouteBranch {
  private _home: RouteLeaf;

  public get home(): RouteLeaf {
    return this._home;
  }

  public set home(value: RouteLeaf) {
    this.replace(this._home, value);
    this._home = value;
  }
}
