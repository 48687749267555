import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAppConfiguration} from '@application/configuration/application-config.interface';
import {AppSettings} from '@application/configuration/appsettings';

@Injectable()
export class AppConfigurationService implements IAppConfiguration {
  private static readonly _envUrl = '/env.json';
  private _config: AppSettings;
  private _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }
  public load(): Promise<void> {
    const promise = this._httpClient
      .get(AppConfigurationService._envUrl)
      .toPromise()
      .then((config: AppSettings) => {
        this._config = config;
        return Promise.resolve();
      })
      .catch((error: any) => {
        console.log(error);
        // throw new LoadRuntimeApplicationEnvironmentError();
      });

    return promise;
  }

  public get(): AppSettings {
    return this._config;
  }

  // Gets a value of specified property in the configuration file
  public getValue(key: any): any {
    return this._config[key];
  }

  // Is app in the development mode?
  // isDevmode() {
  //   return this._env === 'development';
  // }
  // // Gets API route based on the provided key
  // getApi(key: string): string {
  //   return this._config['API_ENDPOINTS'][key];
  // }
}
