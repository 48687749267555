import {Language} from '@application/translation/language/language.enum';

export class LocalizedName {
  public constructor(public readonly name: string = null, public readonly language: Language | string = null, public readonly id: number = null) {}

  public static fromJSON(data: any): LocalizedName {
    return new LocalizedName(data.name, data.language, data.id);
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      language: this.language
    } as any as JSON;
  }

  public toIdJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      language: this.language
    } as any as JSON;
  }
}
