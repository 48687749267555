import {isNil} from 'lodash-es';
import {ModulePermissions} from '../organization/role/module-permissions';

export class TokenResponse {
  public readonly userId: number = null;
  public readonly userCode: string = null;
  public readonly englishName: string = null;
  public readonly chineseName: string = null;
  public readonly email: string = null;
  public readonly phoneNumber: string = null;
  public readonly isActive: string = null;
  public readonly customerUserId: number = null;
  public readonly userPermissions: ModulePermissions[] = [];
  public readonly areaIds: number[] = [];
  public readonly isSystemAdmin: boolean = null;

  public constructor(data: any = null) {
    if (!isNil(data)) {
      Object.assign(this, data);
      if (!isNil(data.userPermissions)) {
        this.userPermissions = (JSON.parse(data.userPermissions) ?? []).map((x: ModulePermissions) => ModulePermissions.fromJSON(x));
      }
    }
  }

  public static fromJSON(data: any): TokenResponse {
    return new TokenResponse(data);
  }
}
