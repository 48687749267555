import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {StatusType} from '@domain/status-type.enum';
import {BaseComponent} from '@presentation/base-component';

@Component({
  templateUrl: 'remark-status-dialog.component.html',
  styleUrls: ['./remark-status-dialog.component.scss']
})
export class RemarkStatusDialogComponent extends BaseComponent {
  public dialogRef: MatDialogRef<RemarkStatusDialogComponent>;
  public status: number;
  public remark: string;
  public statusType: StatusType;
  public title: string;

  public constructor(dialogRef: MatDialogRef<RemarkStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    super();
    this.dialogRef = dialogRef;
    this.status = data.status;
    this.remark = data.remark;
    this.statusType = data.statusType;
    this.title = data.title;
  }
}
