import {CustomerPriceFormRoute} from '../product-catalogue/customer-price-form-route';
import {RouteBranch} from '../route-branch';
import {CustomerRoute} from './customer-route';
import {CustomerUserRelationshipRoute} from './customer-user-relationship-route';
import {RegistrationRoute} from './registration-route';

export class CustomerRelationshipRoute extends RouteBranch {
  private _registration: RegistrationRoute;
  private _customer: CustomerRoute;
  private _customerUserRelationship: CustomerUserRelationshipRoute;
  private _customerPriceForm: CustomerPriceFormRoute;

  public get registration(): RegistrationRoute {
    return this._registration;
  }

  public set registration(value: RegistrationRoute) {
    this.replace(this._registration, value);
    this._registration = value;
  }

  public get customer(): CustomerRoute {
    return this._customer;
  }

  public set customer(value: CustomerRoute) {
    this.replace(this._customer, value);
    this._customer = value;
  }

  public get customerUserRelationship(): CustomerUserRelationshipRoute {
    return this._customerUserRelationship;
  }

  public set customerUserRelationship(value: CustomerUserRelationshipRoute) {
    this.replace(this._customerUserRelationship, value);
    this._customerUserRelationship = value;
  }

  public get customerPriceForm(): CustomerPriceFormRoute {
    return this._customerPriceForm;
  }
  public set customerPriceForm(value: CustomerPriceFormRoute) {
    this.replace(this._customerPriceForm, value);
    this._customerPriceForm = value;
  }
}
