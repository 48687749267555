export class GeneralResponse {
  private _success: boolean;

  public constructor(success: boolean) {
    this._success = success;
  }

  public get success(): boolean {
    return this._success;
  }

  public set success(success: boolean) {
    this._success = success;
  }

  public static fromJSON(generalJSON: any): GeneralResponse {
    return new GeneralResponse(generalJSON.success);
  }
}
