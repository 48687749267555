import {ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DataFormatter} from '@application/helper/data-formatter';
import {TranslateService} from '@application/translation/translate.service';
import {Module} from '@domain/organization/role/module.enum';
import {regExps} from '@domain/regex-format';
import {SelectOption} from '@domain/select-option.interface';
import {CustomerOptionResponse} from '@infrastructure/http/customer-relationship/customer/customer-option.response';
import {CustomerResponse} from '@infrastructure/http/customer-relationship/customer/customer.response';
import {CUSTOMER, ICustomerService} from '@infrastructure/http/customer-relationship/customer/http-customer.interface';
import {SharedCustomerDataOptionResponse} from '@infrastructure/http/customer-relationship/customer/shared-customer-data-option.response';
import {BaseComponent} from '@presentation/base-component';
import {isNil} from 'lodash-es';
import {finalize, takeUntil} from 'rxjs/operators';
import {ServerSideDropDown} from './server-side-drop-drop.enum';

@Component({
  selector: 'app-server-side-drop-down',
  templateUrl: './server-side-drop-down.component.html',
  styleUrls: ['./server-side-drop-down.component.scss']
})
export class ServerSideDropDownComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() public inputFieldTitle: string;
  @Input() public dropdownControl: string;
  @Input() public service: ServerSideDropDown;
  @Input() public selectedId: number;
  @Input() public formGroup: FormGroup;
  @Input() public isRequired = false;
  @Input() public ignoredIds: number[] = [];
  @Input() public isFilteredByOuAreas = false;
  @Input() public selectedRelationType?: number;
  @Input() public isClearValue = false;
  @Input() public isRequiredOemPermissionValidation?: boolean;
  @Input() public module?: Module;
  @Input() public internalCompanyId?: number;
  @Input() public isShowCustomerCategoryName: boolean;
  @Input() public searchPlaceHolder = 'GENERAL.SEARCH';
  @Input() public isFilterByPermission?: boolean = false;

  public keywordSearch: string;
  public offSet = 0;
  public limit = 10;
  public total = 0;
  public data = [];
  public options: SelectOption[] = [];
  public searchBoxControl: string;
  public isLoading = true;

  private readonly _customerService: ICustomerService;
  private readonly _formBuilder: FormBuilder;
  private readonly _translateService: TranslateService;
  private readonly _dataFormatter: DataFormatter;
  private preSelected: any;

  public constructor(@Inject(CUSTOMER) customerService: ICustomerService, formBuilder: FormBuilder, translateService: TranslateService, private readonly _changeDetectorRef: ChangeDetectorRef) {
    super();
    this._customerService = customerService;
    this._formBuilder = formBuilder;
    this._translateService = translateService;
    this._dataFormatter = new DataFormatter(translateService);
  }

  ngOnInit(): void {
    this.searchBoxControl = `search${this.dropdownControl}`;
    this.formGroup.addControl(this.searchBoxControl, this._formBuilder.control(null));
    this.getNextBatch();
    this.initializeFormOptions();
  }

  ngOnChanges(changes: any): void {
    if (changes.selectedId?.newValue !== changes.selectedId?.previousValue) {
      this.onChange();
    }
  }

  public filterChanged(newValue: string): void {
    if (!regExps.alphanumericChinese.test(newValue)) {
      newValue = newValue.replace(regExps.invalidAlphanumericChinese, '');
      this.formGroup.controls[this.searchBoxControl].setValue(newValue, {emitEvent: false});
    }
  }

  public getNextBatch(): void {
    this.isLoading = true;
    this.formGroup.controls[this.searchBoxControl].disable({emitEvent: false});
    switch (this.service) {
      case ServerSideDropDown.CUSTOMER:
        this._customerService
          .getOptions(
            this.selectedId,
            this.keywordSearch,
            this.offSet,
            this.limit,
            'id',
            'asc',
            this.ignoredIds,
            this.isFilteredByOuAreas,
            this.selectedRelationType,
            this.isRequiredOemPermissionValidation,
            this.module,
            this.internalCompanyId,
            this.isFilterByPermission
          )
          .pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeProcessing()))
          .subscribe((response: [any, number]) => {
            this.total = response[1];
            this.offSet += 10;
            this.limit += 10;

            if (this.total === 0) {
              this.options = [];
              this.options.push({value: null, name: this._translateService.instant('GENERAL.NO_RESULTS')});
            }

            const result = response[0];
            const updatedResult: SelectOption[] = result.map((selection: CustomerOptionResponse) => {
              const customerResponse = new CustomerResponse();
              customerResponse.id = selection.id;
              customerResponse.code = selection.code;
              customerResponse.mainContactPerson = selection.mainContactPerson;
              customerResponse.companyName = selection.companyName;
              customerResponse.companyRelationType = selection.companyRelationType;
              customerResponse.status = selection.status;

              const name = this.isShowCustomerCategoryName
                ? `${this._dataFormatter.formatCompanyName(customerResponse)} - ${selection.customerCategory}`
                : this._dataFormatter.formatCompanyName(customerResponse);

              return {
                value: selection,
                name: name
              };
            });

            this.options = this.options.concat(updatedResult);
            this.options = [...new Map(this.options.map((item: any) => [item['value'], item])).values()];

            if (!isNil(this.selectedId)) {
              this.keywordSearch = result[0].companyName;
              this.formGroup.controls[this.searchBoxControl].setValue(this.keywordSearch, {emitEvent: false});
              this.formGroup.controls[this.dropdownControl].setValue(result[0]);
            }

            this.formGroup.controls[this.searchBoxControl].enable({emitEvent: false});
            this.isLoading = false;
            this._changeDetectorRef.markForCheck();
          });
        break;

      case ServerSideDropDown.SHARED_CUSTOMER:
        this.getSharedCustomerOptions();
        break;
    }
  }

  public getErrorMessage(): string {
    let errorMessage = '';
    const entityName = this._translateService.instant(this.inputFieldTitle).toLowerCase();

    if (this.formGroup.get(this.dropdownControl).hasError('required')) {
      errorMessage = this._translateService.instant('GENERAL.ERRORS.REQUIRED', {entityName});
    } else if (!isNil(this.formGroup.get(this.dropdownControl).errors)) {
      errorMessage = Object.values(this.formGroup.get(this.dropdownControl).errors)[0];
    }
    return errorMessage;
  }

  public onChange(): void {
    if (!isNil(this.preSelected)) {
      this.options = [];
      switch (this.service) {
        case ServerSideDropDown.CUSTOMER:
          const customerResponse = new CustomerResponse();
          customerResponse.id = this.preSelected.id;
          customerResponse.code = this.preSelected.code;
          customerResponse.mainContactPerson = this.preSelected.mainContactPerson;
          customerResponse.companyName = this.preSelected.companyName;

          const name = this.isShowCustomerCategoryName
            ? `${this._dataFormatter.formatCompanyName(customerResponse)} - ${this.preSelected.customerCategory}`
            : this._dataFormatter.formatCompanyName(customerResponse);

          this.options.push({
            value: this.preSelected,
            name: name
          });
          this.keywordSearch = this.preSelected.companyName;
          const errors = this.formGroup.controls[this.dropdownControl].errors;
          this.formGroup.controls[this.searchBoxControl].setValue(this.keywordSearch, {emitEvent: false});
          this.formGroup.controls[this.dropdownControl].setValue(this.preSelected, {emitEvent: false});
          this.formGroup.controls[this.dropdownControl].setErrors(errors, {emitEvent: false});
          break;

        case ServerSideDropDown.SHARED_CUSTOMER:
          this.options.push({
            value: this.preSelected,
            name: this.preSelected.companyName
          });
          this.keywordSearch = this.preSelected.companyName;
          this.formGroup.controls[this.searchBoxControl].setValue(this.keywordSearch, {emitEvent: false});
          this.formGroup.controls[this.dropdownControl].setValue(this.preSelected, {emitEvent: false});
          break;
      }
    }
  }

  public clearSelection(): void {
    this.keywordSearch = null;
    this.selectedId = null;
    this.preSelected = null;
    this.formGroup.controls[this.searchBoxControl].setValue(null, {emitEvent: false});
    this.formGroup.controls[this.dropdownControl].setValue(null, {emitEvent: false});
    this.options = [];
    this.offSet = 0;
    this.limit = 10;
    this.getNextBatch();
  }

  public search(): void {
    this.keywordSearch = this.formGroup.controls[this.searchBoxControl].value;
    this.selectedId = null;
    this.options = [];
    this.offSet = 0;
    this.limit = 10;
    this.getNextBatch();
  }

  private initializeFormOptions(): void {
    this.formGroup.controls[this.dropdownControl].valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((value: any) => {
      if (!isNil(value) && !this.isClearValue) {
        this.preSelected = value;
      }
    });
  }

  public getSharedCustomerOptions(): void {
    this._customerService
      .getSharedCustomerOptions(this.keywordSearch, this.offSet, this.limit, 'id', 'asc')
      .pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeProcessing()))
      .subscribe((response: [any, number]) => {
        this.total = response[1];
        this.offSet += 10;
        this.limit += 10;

        if (this.total === 0) {
          this.options = [];
          this.options.push({value: null, name: this._translateService.instant('GENERAL.NO_RESULTS')});
        }

        const result = response[0];
        const updatedResult: SelectOption[] = result.map((selection: SharedCustomerDataOptionResponse) => {
          return {
            value: selection,
            name: selection.companyName
          };
        });

        this.options = this.options.concat(updatedResult);
        this.options = [...new Map(this.options.map((item: any) => [item['value'], item])).values()];

        if (!isNil(this.selectedId)) {
          this.keywordSearch = result[0].companyName;
          this.formGroup.controls[this.searchBoxControl].setValue(this.keywordSearch, {emitEvent: false});
          this.formGroup.controls[this.dropdownControl].setValue(result[0]);
        }

        this.formGroup.controls[this.searchBoxControl].enable({emitEvent: false});
        this.isLoading = false;
        this._changeDetectorRef.markForCheck();
      });
  }
}
