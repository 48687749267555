import {Inject, Injectable} from '@angular/core';
import {AppConfiguration} from '@application/configuration/application-config';
import {HttpTransportType, HubConnection, HubConnectionBuilder, IHttpConnectionOptions} from '@microsoft/signalr';
import {BehaviorSubject, Observable} from 'rxjs';
import {AUTHENTICATION, IAuthenticationService} from '../authentication/http-authentication.interface';
import {ISignalRService} from './signalR.interface';

@Injectable()
export class signalRService implements ISignalRService {
  public hubConnection: HubConnection;
  public authenticationService: IAuthenticationService;
  public notifications: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _appConfig: AppConfiguration;

  public constructor(@Inject(AUTHENTICATION) authenticationService, appConfig: AppConfiguration) {
    this.authenticationService = authenticationService;
    this._appConfig = appConfig;
  }

  public startListener(methodName: string): void {
    if (this._appConfig.isNotificationEnabled) {
      if (!this.hubConnection) {
        this.startConnection();
      }

      this.hubConnection.on(methodName, (message) => {
        this.notifications.next(message);
      });
    }
  }

  public getNotifications(): Observable<any> {
    return this.notifications.asObservable();
  }

  public startConnection(): void {
    if (this._appConfig.isNotificationEnabled) {
      const connectionOptions: IHttpConnectionOptions = {
        transport: HttpTransportType.LongPolling
      };

      const url = `${this._appConfig.messagesEndpoint()}notificationHub`;
      this.hubConnection = new HubConnectionBuilder().withUrl(url, connectionOptions).withAutomaticReconnect().build();

      this.hubConnection
        .start()
        .then(() => console.log('Connection started'))
        .catch((err) => console.log('Error while starting connection: ' + err));
    }
  }
}
