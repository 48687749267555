import {Directive, ElementRef, HostListener} from '@angular/core';
@Directive({
  selector: 'input[appInputWholeNumber]'
})
export class InputWholeNumberDirective {
  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    if (
      ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter'].includes(event.key) ||
      // Allow Ctrl+A/Ctrl+C/Ctrl+V
      ((event.key === 'a' || event.key === 'A') && event.ctrlKey) ||
      ((event.key === 'c' || event.key === 'C') && event.ctrlKey) ||
      ((event.key === 'v' || event.key === 'V') && event.ctrlKey)
    ) {
      return;
    }

    // Prevent non-numeric inputs
    if ((event.shiftKey || event.key < '0' || event.key > '9' || event.key > '9') && (event.key < '0' || event.key > '9')) {
      event.preventDefault();
    }
  }
}
