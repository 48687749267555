import {RouteBranch} from '../route-branch';
import {ContractRoute} from './contract-route';
import {OrderRoute} from './order-route';
import {PaymentDetailRoute} from './payment-detail-route';
import {PaymentRoute} from './payment-route';
import {QuotationRoute} from './quotation-route';

export class SalesRoute extends RouteBranch {
  private _quotation: QuotationRoute;
  private _contract: ContractRoute;
  private _order: OrderRoute;
  private _payment: PaymentRoute;
  private _paymentDetail: PaymentDetailRoute;

  public get quotation(): QuotationRoute {
    return this._quotation;
  }

  public set quotation(value: QuotationRoute) {
    this.replace(this._quotation, value);
    this._quotation = value;
  }

  public get contract(): ContractRoute {
    return this._contract;
  }

  public set contract(value: ContractRoute) {
    this.replace(this._contract, value);
    this._contract = value;
  }

  public get order(): OrderRoute {
    return this._order;
  }

  public set order(value: OrderRoute) {
    this.replace(this._order, value);
    this._order = value;
  }

  public get payment(): PaymentRoute {
    return this._payment;
  }

  public set payment(value: PaymentRoute) {
    this.replace(this._payment, value);
    this._payment = value;
  }

  public get paymentDetail(): PaymentDetailRoute {
    return this._paymentDetail;
  }

  public set paymentDetail(value: PaymentDetailRoute) {
    this.replace(this._paymentDetail, value);
    this._paymentDetail = value;
  }
}
