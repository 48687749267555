import {InjectionToken} from '@angular/core';
import {IModulePermission} from '@application/directives/has-permission.directive';
import {Subscription} from './subscription';

export const SUBSCRIPTION = new InjectionToken<ISubscriptionService>('ISubscriptionService');

export interface ISubscriptionService {
  getCurrentSubscription(): Subscription;
  setCurrentSubscription(subscription: Subscription): void;
  hasPermission(modulePermission: IModulePermission): boolean;
}
