export class ModulePermissions {
  constructor(public moduleId: number = null, public permissionIds: number[] = []) {}

  public toJSON(): JSON {
    return {
      moduleId: this.moduleId,
      permissionIds: this.permissionIds
    } as any as JSON;
  }

  public static fromJSON(data: any): ModulePermissions {
    return new ModulePermissions(data.moduleId, data.permissionIds);
  }
}
