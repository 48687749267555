import {AfterContentInit, Directive, ElementRef} from '@angular/core';
import {isNil} from 'lodash-es';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {
  private elementRef: ElementRef;

  public constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  public ngAfterContentInit(): void {
    let nativeElement = this.elementRef.nativeElement;
    if (!(nativeElement instanceof HTMLInputElement)) {
      nativeElement = nativeElement.querySelector('input');
    }
    if (!isNil(nativeElement)) {
      setTimeout(() => nativeElement.focus(), 500);
    }
  }
}
