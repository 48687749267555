export class MarkAsReadNotificationRequest {
  private _notificationIds: number[] | null = null;

  public get notificationIds(): number[] {
    return this._notificationIds;
  }
  public set notificationIds(value: number[]) {
    this._notificationIds = value;
  }

  public toJSON(): JSON {
    return {
      notificationIds: this._notificationIds
    } as any as JSON;
  }
}
