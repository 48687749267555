import {BusinessUnitResponse} from '@infrastructure/http/custom-setting/business-units/business-unit.response';
import {UserResponse} from '@infrastructure/http/organization/user/user.response';
import {IdName} from '@infrastructure/id-name';
import {UserCodeName} from '@infrastructure/user-code-name';
import {isNil} from 'lodash-es';
import {ErpCustomerOverviewResponse} from '../erp-customer/response/erp-customer-overview.response';
import {RegistrationContactPerson} from '../registration/registration-contact-person';
import {CustomerAddress} from './customer-address';

export class CustomerResponse {
  constructor(data: any = null) {
    if (!isNil(data)) {
      this.id = data.id || null;
      this.code = data.code || null;
      this.mainContactPerson = RegistrationContactPerson.fromJSON(data.mainContactPerson) || null;
      this.companyName = data.companyName || null;
      this.customerAttribute = IdName.fromJSON(data.customerAttribute) || null;
      this.customerCategory = IdName.fromJSON(data.customerCategory) || null;
      this.enterpriseNature = IdName.fromJSON(data.enterpriseNature) || null;
      this.enterpriseScale = data.enterpriseScale || null;
      this.industry = IdName.fromJSON(data.industry) || null;
      this.area = IdName.fromJSON(data.area) || null;
      this.businessLicenseFileName = data.businessLicenseFileName || null;
      this.addresses = (data.addresses || []).map((address: any) => CustomerAddress.fromJSON(address));
      this.status = IdName.fromJSON(data.status) || null;
      this.companyRelationType = IdName.fromJSON(data.companyRelationType) || null;
      this.parentCompanyId = data.parentCompanyId || null;
      this.createdAt = data.createdAt || null;
      this.modifiedBy = UserCodeName.fromJSON(data.modifiedBy) || null;
      this.modifiedAt = data.modifiedAt || null;
      this.submittedAt = data.submittedAt || null;
      this.submittedBy = data.submittedBy || null;
      this.verifiedAt = data.verifiedAt || null;
      this.verifiedBy = data.verifiedBy || null;
      this.inChargeBy = data.inChargeBy || null;
      this.formId = data.formId || null;
      this.remark = data.remark || null;
      this.isOEMCustomer = data.isOEMCustomer || null;
      this.taxpayerIdentificationNumber = data.taxpayerIdentificationNumber || null;
      this.salesUsers = (data.salesUsers || []).map((user: any) => UserResponse.fromJSON(user));
      this.subCompanyIds = data.subCompanyIds || null;
      this.erpCustomers = data.erpCustomers || null;
      this.businessUnit = !isNil(data.businessUnit) ? BusinessUnitResponse.fromJSON(data.businessUnit) : null;
      this.sharedCustomerInformationIds = data.sharedCustomerInformationIds || null;
      this.discount = data.discount;
      this.discountStartDate = data.discountStartDate;
      this.discountEndDate = data.discountEndDate;
      this.priceAdjustmentRate = data.priceAdjustmentRate;
    }
  }

  id: number | null;
  code: string | null;
  mainContactPerson: RegistrationContactPerson | null;
  companyName: string | null;
  customerAttribute: IdName | null;
  customerCategory: IdName | null;
  enterpriseNature: IdName | null;
  enterpriseScale: string | null;
  industry: IdName | null;
  area: IdName | null;
  businessLicenseFileName: string | null;
  addresses: CustomerAddress[] = [];
  status: IdName | null;
  companyRelationType: IdName | null;
  parentCompanyId: number | null;
  createdAt: Date | null;
  modifiedBy: UserCodeName | null;
  modifiedAt: Date | null;
  submittedAt: Date | null;
  submittedBy: string | null;
  verifiedAt: Date | null;
  verifiedBy: string | null;
  inChargeBy: string | null;
  formId: string | null;
  remark: string | null;
  isOEMCustomer: boolean | null;
  taxpayerIdentificationNumber: string | null;
  salesUsers: UserResponse[] = [];
  subCompanyIds: number[] = [];
  erpCustomers: ErpCustomerOverviewResponse[] = [];
  businessUnit: BusinessUnitResponse | null;
  sharedCustomerInformationIds: number[] = [];
  discount: string | null;
  discountStartDate: Date | null;
  discountEndDate: Date | null;
  priceAdjustmentRate: string | null;

  public static fromJSON(data: any): CustomerResponse {
    const object = new CustomerResponse(data);

    return object;
  }
}
