import {Pipe, PipeTransform} from '@angular/core';
import {NotificationModuleEnum} from '@domain/notification-module.enum';

@Pipe({
  name: 'notificationIcon'
})
export class NotificationIconPipe implements PipeTransform {
  public transform(module: NotificationModuleEnum): string {
    let icon = '';
    switch (module) {
      case NotificationModuleEnum.CUSTOMER:
      case NotificationModuleEnum.REGISTRATION:
        icon = 'notification-customer';
        break;
      case NotificationModuleEnum.PRODUCT:
      case NotificationModuleEnum.PRICING:
      case NotificationModuleEnum.SPARE_PART:
        icon = 'notification-product';
        break;
      case NotificationModuleEnum.QUOTATION:
      case NotificationModuleEnum.CONTRACT:
      case NotificationModuleEnum.ORDER:
      case NotificationModuleEnum.PAYMENT:
        icon = 'notification-sales';
        break;
      case NotificationModuleEnum.ORDER_DELIVERY_FORM:
        icon = 'notification-order-delivery';
        break;
      default:
        icon = '';
        break;
    }

    return icon;
  }
}
