<section fxLayout="column" fxLayoutGap="16px">
  <header fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <h3>{{ advancedDateFilter.title }}</h3>
    <div fxLayoutAlign="end" fxFlex>
      <button mat-icon-button mat-dialog-close class="close-button">
        <mat-icon svgIcon="cross"></mat-icon>
      </button>
    </div>
  </header>
  <article>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'GENERAL.ADVANCED_SEARCH.DATE_RANGE' | translate }}</mat-label>
      <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker">
        <input matStartDate [placeholder]="'GENERAL.ADVANCED_SEARCH.START_DATE' | translate" formControlName="start" />
        <input matEndDate [placeholder]="'GENERAL.ADVANCED_SEARCH.END_DATE' | translate" formControlName="end" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
      </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <div fxLayoutAlign="center center">
      <button class="vdw-button vdw-primary-button" (click)="confirm()">{{ 'GENERAL.ACTIONS.CONFIRM' | translate }}</button>
    </div>
  </article>
</section>
