import {RouteNode} from '@application/routing/route-node';
import {INavigationBarItem, NavigationBarItem} from './navigation-bar-item';

export interface INavigationBarRouteLinkItemProperties extends Omit<INavigationBarItem, 'modulePermission' | 'mobileFriendly' | 'mobileOnly'> {
  route: RouteNode;
}

export class NavigationBarRouteLinkItem extends NavigationBarItem {
  private _route: RouteNode;

  public constructor(properties: INavigationBarRouteLinkItemProperties) {
    const {route, ...navigationMenuItemProperties} = properties;

    super({...navigationMenuItemProperties, modulePermission: route?.modulePermission, mobileFriendly: route?.isMobileFriendly, mobileOnly: route?.isMobileOnly});

    this._route = route;
  }

  public get path(): string {
    return this._route?.absolutePath ?? '';
  }

  public get route(): RouteNode {
    return this._route;
  }
}
