import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DialogHelper} from '@application/helper/dialog-helper';
import {TranslateService} from '@application/translation/translate.service';
import {SalesDocumentType} from '@domain/sales/sales-document-type.enum';
import {BaseComponent} from '@presentation/base-component';
import {AlertDialogResult} from '@presentation/components/alert-dialog/alert-dialog-data';
import {isNil} from 'lodash-es';
import {finalize, takeUntil} from 'rxjs/operators';
import {UploadFileItem} from '../upload-file-item.component';

@Component({
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent extends BaseComponent implements OnInit {
  public selectedFile: UploadFileItem;
  public totalPages: number;
  public contractId: number;
  public quotationId: number;
  public orderId: number;
  public email: string;
  public height: string;
  public page = 0;
  public confirmationPreview = false;
  public documentType: SalesDocumentType;
  public files: UploadFileItem[];
  public title: string;
  public httpRequest: any;

  private readonly _dialog: MatDialog;
  private readonly _dialogConfig: MatDialogConfig = {autoFocus: false, restoreFocus: false};
  private dialogRef: MatDialogRef<FilePreviewComponent>;
  private readonly _router: Router;
  private readonly _translateService: TranslateService;

  public constructor(@Inject(MAT_DIALOG_DATA) data: any, dialogRef: MatDialogRef<FilePreviewComponent>, router: Router, translateService: TranslateService) {
    super();
    this.dialogRef = dialogRef;
    this._router = router;
    this._translateService = translateService;
    if (data != null) {
      this.files = data.files;
      this.confirmationPreview = data.confirmationPreview;
      this.title = data.title;
      this.contractId = data.contractId;
      this.quotationId = data.quotationId;
      this.orderId = data.orderId;
      this.email = data.email;
      this.documentType = data.documentType;
      this.httpRequest = data.httpRequest;
    }
  }

  ngOnInit(): void {
    this.totalPages = this.files.length - 1;
    this.getFile();
    this.height = this.confirmationPreview ? '70vh' : '400px';
  }

  public nextPage(): void {
    if (this.page === this.totalPages) {
      return;
    }

    this.page += 1;
    this.getFile();
  }

  public previousPage(): void {
    if (this.page === 0) {
      return;
    }

    this.page -= 1;
    this.getFile();
  }

  public getFile(): void {
    this.selectedFile = this.files[this.page];
  }

  public setFiles(files: UploadFileItem[]): void {
    this.files = files;
  }

  public downloadFile(): void {
    if (isNil(this.selectedFile?.file)) {
      const url = this.selectedFile.review;
      window.open(url);
    } else {
      const blob = new Blob([this.selectedFile.file], {type: this.selectedFile.file.type});
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.setAttribute('style', 'display: none');
      anchor.href = url;
      anchor.download = isNil(this.selectedFile?.file) ? this.selectedFile.name : this.selectedFile.file.name;
      anchor.click();
      window.URL.revokeObjectURL(url);
      anchor.remove();
    }
  }

  public cancel(): void {
    this.dialogRef.close(AlertDialogResult.CANCEL);
  }

  public confirm(): void {
    this.processing = true;

    this.httpRequest.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeProcessing())).subscribe(
      () => {
        this.processing = false;
        this.dialogRef.close(AlertDialogResult.CONFIRM);
      },
      () => this.showFailDialog()
    );
  }

  private showFailDialog(): void {
    DialogHelper.showSubmittedFailDialog(this._dialog, this._dialogConfig, this._translateService, 'GENERAL.ACTIONS.SUBMITTED_OBJECT', 'GENERAL.FAIL', 'SALES.EMAIL.SENT_FAIL');
  }
}
