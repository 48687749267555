import {Component, Inject} from '@angular/core';
import {isNil} from 'lodash-es';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';
import {CustomMessageToast, CUSTOM_MESSAGE_TOAST} from './custom-message-toast';
import {animateToast} from './custom-message-toast.animation';

@Component({
  templateUrl: './custom-message-toast.component.html',
  styleUrls: ['./custom-message-toast.component.scss'],
  animations: [animateToast()],
  preserveWhitespaces: false
})
export class CustomMessageToastComponent extends Toast {
  public toastPackage: ToastPackage;
  public titleIcon: string;
  public statusBarClass = '';
  public action: () => void;

  protected toastrService: ToastrService;
  private readonly customMessageToast: CustomMessageToast;

  public constructor(toastrService: ToastrService, toastPackage: ToastPackage, @Inject(CUSTOM_MESSAGE_TOAST) customMessageToast: CustomMessageToast) {
    super(toastrService, toastPackage);
    this.toastrService = toastrService;
    this.toastPackage = toastPackage;
    this.customMessageToast = customMessageToast;
  }

  public dismiss(): void {
    this.customMessageToast.markToastClicked();
    this.remove();
  }

  public canShowActionButton(): boolean {
    return !isNil(this.action);
  }
}
