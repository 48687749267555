import {IModulePermission} from '@application/directives/has-permission.directive';

export interface INavigationBarItem {
  id: string;
  modulePermission: IModulePermission;
  name: string;
  icon: string;
  sort: number;
  mobileFriendly: boolean;
  mobileOnly: boolean;
  subItems: INavigationBarItem[];
}

export abstract class NavigationBarItem implements INavigationBarItem {
  private _id: string;
  private _modulePermission: IModulePermission;
  private _name: string;
  private _icon: string;
  private _sort: number;
  private _mobileFriendly: boolean;
  private _mobileOnly: boolean;
  private _subItems: INavigationBarItem[];

  public constructor(properties: INavigationBarItem) {
    const {id, modulePermission, name, icon, sort, mobileFriendly, mobileOnly, subItems} = properties;

    this._id = id;
    this._modulePermission = modulePermission;
    this._name = name;
    this._icon = icon;
    this._sort = sort;
    this._mobileFriendly = mobileFriendly;
    this._mobileOnly = mobileOnly;
    this._subItems = subItems;
  }

  public get id(): string {
    return this._id;
  }

  public get modulePermission(): IModulePermission {
    return this._modulePermission;
  }

  public get name(): string {
    return this._name;
  }

  public get icon(): string {
    return this._icon;
  }

  public set icon(icon: string) {
    this._icon = icon;
  }

  public get sort(): number {
    return this._sort;
  }

  public get mobileFriendly(): boolean {
    return this._mobileFriendly;
  }

  public get mobileOnly(): boolean {
    return this._mobileOnly;
  }

  public get subItems(): INavigationBarItem[] {
    return this._subItems;
  }

  public set subItems(subItems: INavigationBarItem[]) {
    this._subItems = subItems;
  }
}
