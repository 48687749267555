import {isNil} from 'lodash-es';

export class UserCodeName {
  private _code: string;
  private _name: string;

  public constructor(code: string, name: string = null) {
    this._code = code;
    this._name = name;
  }

  public get code(): string {
    return this._code;
  }

  public set code(code: string) {
    this._code = code;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public static fromJSON(data: any): UserCodeName {
    return !isNil(data) ? new UserCodeName(data.code, data.name) : null;
  }
}
