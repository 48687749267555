import {RouteBranch} from '../route-branch';
import {PricingRoute} from './pricing-route';
import {ProductCategoryGroupRoute} from './product-category-group-route';
import {ProductRoute} from './product-route';
import {SparePartRoute} from './spare-part-route';

export class ProductCatalogueRoute extends RouteBranch {
  private _product: ProductRoute;
  private _sparePart: SparePartRoute;
  private _pricing: PricingRoute;
  private _productCategoryGroup: ProductCategoryGroupRoute;

  public get product(): ProductRoute {
    return this._product;
  }

  public set product(value: ProductRoute) {
    this.replace(this._product, value);
    this._product = value;
  }

  public get sparePart(): SparePartRoute {
    return this._sparePart;
  }

  public set sparePart(value: SparePartRoute) {
    this.replace(this._sparePart, value);
    this._sparePart = value;
  }

  public get pricing(): PricingRoute {
    return this._pricing;
  }

  public set pricing(value: PricingRoute) {
    this.replace(this._pricing, value);
    this._pricing = value;
  }

  public get productCategoryGroup(): ProductCategoryGroupRoute {
    return this._productCategoryGroup;
  }

  public set productCategoryGroup(value: ProductCategoryGroupRoute) {
    this.replace(this._productCategoryGroup, value);
    this._productCategoryGroup = value;
  }
}
