export enum StatusColor {
  GREEN = 1,
  GREY,
  DARK_GREY,
  YELLOW,
  DARK_YELLOW,
  ORANGE,
  RED,
  BRIGHT_YELLOW,
  DARK_RED,
  LIGHT_RED
}
