import {AddressType} from '@domain/customer/address-type.enum';
import {IdName} from '@infrastructure/id-name';
import {Address} from '../address';
import {Contact} from '../contact';

export class CustomerAddress extends Address {
  private _contacts: Contact[];

  public constructor(
    addressType: AddressType,
    country: IdName = null,
    street: string = null,
    postalCode: string = null,
    province: IdName = null,
    city: IdName = null,
    district: IdName = null,
    contacts: Contact[] = null
  ) {
    super(addressType, country, street, postalCode, province, city, district);
    this._contacts = contacts;
  }

  public get contacts(): Contact[] {
    return this._contacts;
  }

  public set contacts(contacts: Contact[]) {
    this._contacts = contacts;
  }

  public static convert(address: Address): CustomerAddress {
    return new CustomerAddress(address.addressType, address.country, address.street, address.postalCode, address.province, address.city, address.district);
  }

  public static fromJSON(data: any): CustomerAddress {
    return new CustomerAddress(
      data.addressType.id,
      data.country,
      data.street,
      data.postalCode,
      data.province,
      data.city,
      data.district,
      data.contacts?.map((c: any) => Contact.fromJSON(c))
    );
  }

  public toJSON(): JSON {
    return {
      addressTypeId: Number(this.addressType),
      countryId: this.country?.id,
      provinceId: this.province?.id,
      cityId: this.city?.id,
      districtId: this.district?.id,
      street: this.street,
      postalCode: this.postalCode,
      contacts: this._contacts?.map((contact: Contact) => contact.toJSON())
    } as any as JSON;
  }
}
