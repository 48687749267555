import {RouteBranch} from '../route-branch';
import {OrganizationalStructureRoute} from './organizational-structure-route';
import {OrganizationalTreeChartRoute} from './organizational-tree-chart-route';
import {RoleRoute} from './role-route';
import {UserRoute} from './user-route';

export class OrganizationRoute extends RouteBranch {
  private _role: RoleRoute;
  private _user: UserRoute;
  private _organizationalStruture: OrganizationalStructureRoute;
  private _organizationTreeChart: OrganizationalTreeChartRoute;

  public get role(): RoleRoute {
    return this._role;
  }

  public set role(value: RoleRoute) {
    this.replace(this._role, value);
    this._role = value;
  }

  public get user(): UserRoute {
    return this._user;
  }

  public set user(value: UserRoute) {
    this.replace(this._user, value);
    this._user = value;
  }

  public get organizationalStructure(): OrganizationalStructureRoute {
    return this._organizationalStruture;
  }

  public set organizationalStructure(value: OrganizationalStructureRoute) {
    this.replace(this._organizationalStruture, value);
    this._organizationalStruture = value;
  }

  public get organizationTreeChart(): OrganizationalTreeChartRoute {
    return this._organizationTreeChart;
  }

  public set organizationTreeChart(value: OrganizationalTreeChartRoute) {
    this.replace(this._organizationTreeChart, value);
    this._organizationTreeChart = value;
  }
}
