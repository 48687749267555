import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from '@application/helper/subscription/subscription';
import {ISubscriptionService, SUBSCRIPTION} from '@application/helper/subscription/subscription.interface';
import {RouteUtils} from '@application/routing/route-utils';
import {AUTHENTICATION, IAuthenticationService} from '@infrastructure/http/authentication/http-authentication.interface';
import {keys, map} from 'lodash-es';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {allowAnonymousUrls} from './allow-anonymous-urls';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    @Inject(AUTHENTICATION) private readonly _authenticationService: IAuthenticationService,
    @Inject(SUBSCRIPTION) private _subscriptionService: ISubscriptionService,
    private readonly _router: Router
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.matchUrl(request.url, allowAnonymousUrls) && !request.headers.get('authorization')) {
      request = request.clone({ withCredentials: true });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          return this.refreshToken(request, next);
        } else if (error.status === HttpStatusCode.Forbidden) {
          this._router.navigateByUrl(RouteUtils.paths.forbidden.absolutePath);
          return new Observable<HttpEvent<any>>();  // // Prevent further error handling in components that might trigger error dialog
        } else {
          return throwError(error);
        }
      })
    );
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this._authenticationService.refreshToken().pipe(
        switchMap((refreshTokenResponse: any) => {
          const decodedToken = this._authenticationService.decodeToken(refreshTokenResponse.accessToken);
          this._subscriptionService.setCurrentSubscription(new Subscription(decodedToken.userPermissions));

          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(refreshTokenResponse);

          return next.handle(this.addAuthenticationToken(request, refreshTokenResponse.accessToken));
        }),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          this.logout();
          return new Observable<HttpEvent<any>>();  // Prevent further error handling in components that might trigger error dialog
        }),
        finalize(() => {
          this.refreshTokenInProgress = false;
        })
      );
    } else { //any request runs in parallel from different component will queue until one completes to avoid concurrent refresh token call
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap((refreshTokenResponse) => next.handle(this.addAuthenticationToken(request, refreshTokenResponse.accessToken)))
      );
    }
  }

  private addAuthenticationToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }
    return request;
  }

  private logout(): void {
    this._authenticationService.logout().pipe(
      finalize(() => {
        this._authenticationService.removeLocalStorage();
        this._router.navigateByUrl(RouteUtils.paths.unauthorized.absolutePath);
      })
    ).subscribe();
  }

  private matchUrl(requestUrl: string, urls: any): boolean {
    const urlValues = map(keys(urls), value => urls[value]);
    return urlValues.some(url => requestUrl.includes(url));
  }
}
