import {Injectable} from '@angular/core';
import {MessageToast} from './sa-message-toast';

@Injectable()
export class SaMessageToastService {
  public showErrorToast(message: string): MessageToast {
    return new MessageToast(true, 'error', message);
  }

  public showWarningToast(message: string): MessageToast {
    return new MessageToast(true, 'warning', message);
  }

  public clearToast(): MessageToast {
    return new MessageToast(false);
  }
}
