export class AuthResponse {
  private _accessToken: string;
  private _expiresIn: number;
  private _tokenType: string;
  private _forceChangeRequired: boolean;

  public constructor(accessToken: string, expiresIn: number, tokenType: string, forceChangeRequired: boolean) {
    this._accessToken = accessToken;
    this._expiresIn = expiresIn;
    this._tokenType = tokenType;
    this._forceChangeRequired = forceChangeRequired;
  }

  public get accessToken(): string {
    return this._accessToken;
  }

  public set accessToken(accessToken: string) {
    this._accessToken = accessToken;
  }

  public get expiresIn(): number {
    return this._expiresIn;
  }

  public set expiresIn(expiresIn: number) {
    this._expiresIn = expiresIn;
  }

  public get tokenType(): string {
    return this._tokenType;
  }

  public set tokenType(tokenType: string) {
    this._tokenType = tokenType;
  }

  public get forceChangeRequired(): boolean {
    return this._forceChangeRequired;
  }

  public set forceChangeRequired(forceChangeRequired: boolean) {
    this._forceChangeRequired = forceChangeRequired;
  }

  public static fromJSON(authJSON: any): AuthResponse {
    return new AuthResponse(authJSON.accessToken, authJSON.expiresIn, authJSON.tokenType, authJSON.forceChangeRequired);
  }
}
