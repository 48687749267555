import {Location} from '@angular/common';
import {HttpStatusCode} from '@angular/common/http';
import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {RouteUtils} from '@application/routing/route-utils';
import {TranslateService} from '@application/translation/translate.service';

@Component({
  selector: 'app-status-code-template',
  templateUrl: './status-code-template.component.html',
  styleUrls: ['./status-code-template.component.scss']
})
export class StatusCodeTemplateComponent {
  @Input() httpStatusCode: HttpStatusCode;
  @Input() localizedName: string;

  private translate: TranslateService;
  private readonly router: Router;
  private location: Location;

  public constructor(translate: TranslateService, router: Router, location: Location) {
    this.translate = translate;
    this.router = router;
    this.location = location;
  }

  public getStatusCode(): string {
    const statusCode = this.httpStatusCode.valueOf();
    return statusCode.toString();
  }

  public getDescription(): string {
    return this.translate.instant(`GENERAL.ERRORS.STATUSCODE.${this.localizedName}.DESCRIPTION`);
  }

  public navigateBackTo(): void {
    if (this.httpStatusCode === HttpStatusCode.Unauthorized) {
      this.router.navigateByUrl(RouteUtils.paths.authentication.login.absolutePath);
    } else if (this.httpStatusCode === HttpStatusCode.Forbidden) {
      this.router.navigateByUrl(RouteUtils.paths.profile.absolutePath);
    } else {
      this.location.back();
    }
  }
}
