import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class ComponentsRoute extends RouteBranch {
  private _colours: RouteLeaf;
  private _spacing: RouteLeaf;
  private _typography: RouteLeaf;
  private _icons: RouteLeaf;
  private _actions: RouteLeaf;
  private _forms: RouteLeaf;

  public get colours(): RouteLeaf {
    return this._colours;
  }

  public set colours(value: RouteLeaf) {
    this.replace(this._colours, value);
    this._colours = value;
  }

  public get spacing(): RouteLeaf {
    return this._spacing;
  }

  public set spacing(value: RouteLeaf) {
    this.replace(this._spacing, value);
    this._spacing = value;
  }

  public get typography(): RouteLeaf {
    return this._typography;
  }

  public set typography(value: RouteLeaf) {
    this.replace(this._typography, value);
    this._typography = value;
  }

  public get icons(): RouteLeaf {
    return this._icons;
  }

  public set icons(value: RouteLeaf) {
    this.replace(this._icons, value);
    this._icons = value;
  }

  public get actions(): RouteLeaf {
    return this._actions;
  }

  public set actions(value: RouteLeaf) {
    this.replace(this._actions, value);
    this._actions = value;
  }

  public get forms(): RouteLeaf {
    return this._forms;
  }

  public set forms(value: RouteLeaf) {
    this.replace(this._forms, value);
    this._forms = value;
  }
}
