import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {MAT_SELECT_CONFIG} from '@angular/material/select';
import {Router} from '@angular/router';
import {DialogHelper} from '@application/helper/dialog-helper';
import {ShortNameHelper} from '@application/helper/short-name-helper';
import {ISubscriptionService, SUBSCRIPTION} from '@application/helper/subscription/subscription.interface';
import {NotificationDetailRoute} from '@application/routing/notification/notification-detail.route';
import {RouteUtils} from '@application/routing/route-utils';
import {TranslateService} from '@application/translation/translate.service';
import {NotificationModuleEnum} from '@domain/notification-module.enum';
import {Permission} from '@domain/organization/role/permission.enum';
import {AUTHENTICATION, IAuthenticationService} from '@infrastructure/http/authentication/http-authentication.interface';
import {LocalizedName} from '@infrastructure/http/localized-name';
import {ISignalRService, SIGNALR_SERVICE} from '@infrastructure/http/message-centre/signalR.interface';
import {IHttpNotificationService, NOTIFICATION} from '@infrastructure/http/notification/http-notification.interface';
import {NotificationService} from '@infrastructure/http/notification/notification.service';
import {UserResponse} from '@infrastructure/http/organization/user/user.response';
import {BaseComponent} from '@presentation/base-component';
import {CustomMessageToast, CUSTOM_MESSAGE_TOAST} from '@presentation/components/custom-message-toast/custom-message-toast';
import {isNil, replace} from 'lodash-es';
import {finalize, takeUntil} from 'rxjs/operators';
import {ThemeConstantService} from '../../services/theme-constant.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {overlayPanelClass: 'select-menu-panel'}
    }
  ],
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit, AfterViewChecked {
  @ViewChild('userName') public userName: ElementRef;
  @ViewChild('notificationListing') public notificationListing: ElementRef;
  @ViewChild('notificationButton') public notificationButton: MatIcon;

  public readonly addPermission = {module: RouteUtils.paths.messageCentre.module, requiredPermission: Permission.ADD};
  public readonly viewPermission = {module: RouteUtils.paths.messageCentre.module, requiredPermission: Permission.VIEW};
  public quickViewVisible = false;
  public isFolded: boolean;
  public isExpand: boolean;
  public isShowToolTip: boolean;
  public hasPermission: boolean;
  public avatarUrl: string;
  public profile: UserResponse;
  public isShowNotificationListing = false;
  public notificationOverviewResponse = [];
  public totalUnreadNotification = 0;
  public isLoading = true;

  private readonly _dialog: MatDialog;
  private readonly _router: Router;
  private readonly _authenticationService: IAuthenticationService;
  private readonly _subscriptionService: ISubscriptionService;
  private readonly changeDetectorRef: ChangeDetectorRef;
  private readonly _signalRService: ISignalRService;
  private themeService: ThemeConstantService;
  private searchVisible = false;
  private translate: TranslateService;
  private readonly customMessageToast: CustomMessageToast;
  private readonly _httpNotificationService: IHttpNotificationService;
  private readonly _notificationService: NotificationService;

  public constructor(
    @Inject(AUTHENTICATION) authentication: IAuthenticationService,
    @Inject(SUBSCRIPTION) subscriptionService: ISubscriptionService,
    @Inject(CUSTOM_MESSAGE_TOAST) customMessageToast: CustomMessageToast,
    @Inject(NOTIFICATION) httpNotificationService: IHttpNotificationService,
    changeDetectorRef: ChangeDetectorRef,
    router: Router,
    themeService: ThemeConstantService,
    translate: TranslateService,
    @Inject(SIGNALR_SERVICE) signalRService: ISignalRService,
    notificationService: NotificationService,
    dialog: MatDialog
  ) {
    super();
    this._router = router;
    this._authenticationService = authentication;
    this._subscriptionService = subscriptionService;
    this.changeDetectorRef = changeDetectorRef;
    this._httpNotificationService = httpNotificationService;
    this.themeService = themeService;
    this.translate = translate;
    this._signalRService = signalRService;
    this.customMessageToast = customMessageToast;
    this._notificationService = notificationService;
    this._dialog = dialog;
  }

  public ngOnInit(): void {
    this.getProfile();
    this.themeService.isMenuFoldedChanges.subscribe((isFolded: boolean) => (this.isFolded = isFolded));
    this.themeService.isExpandChanges.subscribe((isExpand: boolean) => (this.isExpand = isExpand));
    this.hasPermission = this._subscriptionService.hasPermission(this.addPermission) && this._subscriptionService.hasPermission(this.viewPermission);
    this.initializePage();
    this._notificationService.getMarkAsReadObservable().subscribe(() => {
      this.totalUnreadNotification--;
    });
  }

  public ngAfterViewChecked(): void {
    this.isShowToolTip = this.canShowToolTip();
    this.changeDetectorRef.detectChanges();
  }

  public toggleFold(): void {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  public toggleExpand(): void {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  public searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  public quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  public canShowQuickView(): boolean {
    return window.innerWidth < 600 && this.isExpand;
  }

  public getHeaderLogo(): string {
    let result = '';
    if (this.isFolded) {
      result = 'assets/images/logo/vdw-logo.svg';
    } else {
      result = 'assets/images/logo/vdw-logo-en-2.svg';
    }

    return result;
  }

  public canShowToolTip(): boolean {
    return !isNil(this.userName) ? this.userName.nativeElement.offsetWidth < this.userName.nativeElement.scrollWidth : false;
  }

  public navigateToProfile(): void {
    this._router.navigateByUrl(RouteUtils.paths.profile.absolutePath).then(() => {
      window.location.reload();
    });
  }

  public navigateToLogout(): void {
    this._authenticationService
      .logout()
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        finalize(() => {
          this._authenticationService.removeLocalStorage();
          this._router.navigateByUrl(RouteUtils.paths.authentication.logout.absolutePath);
        })
      )
      .subscribe();
  }

  public navigateToBroadcastMessage(): void {
    this._router.navigateByUrl(RouteUtils.paths.messageCentre.absolutePath);
  }

  public navigateToNotificationListing(): void {
    this.isShowNotificationListing = false;
    this._router.navigateByUrl(RouteUtils.paths.notification.absolutePath);
  }

  public getShortName(): string {
    return ShortNameHelper.shortName(this.profile?.localizedNames?.find((m: LocalizedName) => m.language === this.translate.getLocale().language.toUpperCase())?.name);
  }

  public getName(): string {
    return this.profile?.localizedNames?.find((m: LocalizedName) => m.language === this.translate.getLocale().language.toUpperCase())?.name;
  }

  public openNotificationListing(): void {
    this.isShowNotificationListing = !this.isShowNotificationListing;
    if (this.isShowNotificationListing) {
      this.getNotificationListings();
    }
  }

  public onRouteClicked(module: NotificationModuleEnum, entityId: number, notificationId: number, isRead: boolean): void {
    if (!isRead) {
      this._httpNotificationService.markAsRead([notificationId]).subscribe(() => {
        this.totalUnreadNotification--;
        this.notificationOverviewResponse.find((f) => f.id === notificationId).isRead = true;
      });
    }

    window.open(replace(NotificationDetailRoute.transform(module), ':id', String(entityId)), '_blank');
  }

  private getProfile(): void {
    this._authenticationService
      .getProfile()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(
        (user: UserResponse) => {
          this.profile = user;
          this.avatarUrl = user.avatarUrl;
        },
        () => this.showFailDialog()
      );
  }

  private getNotificationListings(): void {
    this.isLoading = true;
    this._httpNotificationService
      .getPaginated(0, 6, 'id', 'desc')
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((response: any) => {
        this.notificationOverviewResponse = response[0];
        this.totalUnreadNotification = response[2];
        this.isLoading = false;
      });
  }

  private showFailDialog(): void {
    DialogHelper.showErrorDialog(
      this._dialog,
      {autoFocus: false, restoreFocus: false},
      this.translate.instant('GENERAL.ERRORS.SYSTEM_ERROR'),
      this.translate.instant('GENERAL.ERRORS.SOMETHING_WRONG'),
      () => window.location.reload()
    );
  }

  private initializePage(): void {
    this.getNotificationListings();
    this._signalRService.startListener('notification');
    this._signalRService
      .getNotifications()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((x) => {
        if (!isNil(x)) {
          this.totalUnreadNotification++;
          this.customMessageToast.showInfoToast({
            tapToDismiss: true,
            timeOut: 3000,
            title: this.translate.instant('MESSAGE_CENTRE.NOTIFICATION.TITLE'),
            message: this.translate.instant('MESSAGE_CENTRE.NOTIFICATION.RECEIVE_NEW_NOTIFICATION'),
            positionClass: 'toast-top-right'
          });
        }
      });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.notificationListing?.nativeElement?.contains(event.target) && !this.notificationButton?._elementRef?.nativeElement?.contains(event.target)) {
      this.isShowNotificationListing = false;
    }
  }
}
