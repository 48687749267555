<div fxLayout="column" fxFlex>
  <div class="mat-dialog-header" fxLayout="column" fxLayoutAlign="center center">
    <h2 mat-dialog-title>{{ title }}</h2>
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>
  <div fxLayout="column">
    <form class="address-form" [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="start center">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ADDRESS.COUNTRY' | translate }}</mat-label>
        <mat-select formControlName="country" (selectionChange)="onCountrySelected($event.value)">
          <mat-option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</mat-option>
        </mat-select>
        <mat-error> {{ getAddressErrorMessage('country') }}</mat-error>
      </mat-form-field>
      <div *ngIf="showProvinceOptions" fxFlex="100" fxLayout="row" fxLayoutGap="8px" class="w-100">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'ADDRESS.PROVINCE' | translate }}</mat-label>
          <mat-select formControlName="province" (selectionChange)="onProvinceSelected($event.value)">
            <mat-option *ngFor="let province of provinces" [value]="province.id">{{ province.name }}</mat-option>
          </mat-select>
          <mat-error> {{ getAddressErrorMessage('province') }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'ADDRESS.CITY' | translate }}</mat-label>
          <mat-select formControlName="city" (selectionChange)="onCitySelected($event.value)">
            <mat-option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</mat-option>
          </mat-select>
          <mat-error> {{ getAddressErrorMessage('city') }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'ADDRESS.DISTRICT' | translate }}</mat-label>
          <mat-select formControlName="district">
            <mat-option *ngFor="let district of districts" [value]="district.id">{{ district.name }}</mat-option>
          </mat-select>
          <mat-error> {{ getAddressErrorMessage('district') }}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ADDRESS.STREET' | translate }}</mat-label>
        <input matInput formControlName="street" />
        <mat-error> {{ getAddressErrorMessage('street') }} </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ADDRESS.POSTAL_CODE' | translate }}</mat-label>
        <input matInput formControlName="postalCode" />
        <mat-error>{{ getAddressErrorMessage('postalCode') }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div fxLayoutAlign="center end" fxLayoutGap="16px">
    <button class="vdw-button vdw-secondary-button" mat-dialog-close>{{ 'GENERAL.ACTIONS.CANCEL' | translate }}</button>
    <app-progress-button class="progress-button" label="GENERAL.ACTIONS.CONFIRM" [alwaysShowLabel]="true" [fullWidth]="false" [inProgress]="processing" (progressClick)="save()"></app-progress-button>
  </div>
  <div class="action-bar" fxLayoutAlign="center end">
    <a class="sa-hyperlink" fxFlexAlign="center" (click)="clearForm()" [class.disabled]="processing">{{ 'GENERAL.ACTIONS.CLEAR' | translate }}</a>
  </div>
</div>
