import {Component, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CountdownConfig, CountdownEvent} from 'ngx-countdown';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-countdown-button',
  templateUrl: './countdown-button.component.html',
  styleUrls: ['./countdown-button.component.scss']
})
export class CountDownButtonComponent implements OnInit {
  @ViewChild('timer', {static: false}) countdown: any;

  @Input() public inProgress = false;
  @Input() public inCountingDown = false;
  @Input() public startCountingDown = false;
  @Input() public label = 'GENERAL.ACTIONS.SAVE';
  @Input() public countingDownLabel = 'GENERAL.ACTIONS.SAVE';
  @Input() public finishedLabel = 'GENERAL.ACTIONS.SAVE';
  @Input() public countDownSeconds = 30;
  @Input() public disabled = false;
  @Input() public raisedButton = true;
  @Input() public labelReplaceableValues = {};
  @Input() public tabIndex = 0;
  @Input() public fullWidth = false;
  @Input() public invalid = false;
  @Output() public progressClick: Subject<void> = new Subject<void>();

  public timerConfig: CountdownConfig = {
    leftTime: this.countDownSeconds,
    demand: true,
    format: 'mm:ss'
  };

  ngOnInit(): void {
    this.timerConfig.leftTime = this.countDownSeconds;
  }

  public isInProgress(): boolean {
    return this.inProgress;
  }

  public isInCountingDown(): boolean {
    return this.inCountingDown;
  }

  public isDisabled(): boolean {
    return this.disabled;
  }

  public getButtonWidth(): string {
    return this.fullWidth ? '100%' : 'auto';
  }

  public getLabel(): string {
    return this.label;
  }

  public startProgress(): void {
    if (!this.isInProgress()) {
      this.progressClick.next();
    }
  }

  public startCountDown(): void {
    if (!this.isInCountingDown()) {
      this.countdown.begin();
      this.inCountingDown = true;
      this.label = this.countingDownLabel;
    }
  }

  public isRaisedButton(): boolean {
    return this.raisedButton;
  }

  public enableButton(e: CountdownEvent): void {
    if (e.action === 'done') {
      this.inCountingDown = false;
      this.label = this.finishedLabel;
      this.countdown.restart();
    }
  }
}
