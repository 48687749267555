import {NotificationModuleEnum} from '@domain/notification-module.enum';

export class NotificationOverviewResponse {
  private _id: number | null = null;
  private _entityId: number | null = null;
  private _content: string | null = null;
  private _isRead: boolean | null = null;
  private _createdAt: Date | null = null;
  private _module: NotificationModuleEnum | null = null;

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }

  public get entityId(): number {
    return this._entityId;
  }
  public set entityId(value: number) {
    this._entityId = value;
  }

  public get content(): string {
    return this._content;
  }
  public set content(value: string) {
    this._content = value;
  }

  public get isRead(): boolean {
    return this._isRead;
  }
  public set isRead(value: boolean) {
    this._isRead = value;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }
  public set createdAt(value: Date) {
    this._createdAt = value;
  }

  public get module(): NotificationModuleEnum {
    return this._module;
  }
  public set module(value: NotificationModuleEnum) {
    this._module = value;
  }

  public static fromJSON(data: any): NotificationOverviewResponse {
    return Object.assign(new NotificationOverviewResponse(), data);
  }
}
