import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {MatTooltip, TooltipPosition} from '@angular/material/tooltip';
import {isUndefined} from 'lodash-es';

@Component({
  selector: 'app-ellipsis-label, [app-ellipsis-label]',
  templateUrl: './ellipsis-label.component.html',
  styleUrls: ['./ellipsis-label.component.scss']
})
export class EllipsisLabelComponent implements AfterViewInit, OnDestroy {
  @Input() public text: string;
  @Input() public matTooltipPosition: TooltipPosition;
  @Input() public tooltipDisabled = false;
  @ViewChild('label') public label: ElementRef<HTMLElement>;
  @ViewChild('ellipsisTooltip') public ellipsisTooltip: MatTooltip;
  public showTooltip = false;

  private resizeObserver: ResizeObserver;
  private mutationObserver: MutationObserver;

  public ngAfterViewInit(): void {
    this.mutationObserver = new MutationObserver(() => {
      this.showTooltip = this.canShowTooltip();
    });

    this.resizeObserver = new ResizeObserver(() => {
      this.showTooltip = this.canShowTooltip();
    });

    this.resizeObserver.observe(this.label.nativeElement, {box: 'border-box'});
    this.mutationObserver.observe(this.label.nativeElement, {characterData: true, attributes: false, childList: false, subtree: true});
  }

  public ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.label.nativeElement);
    this.mutationObserver.disconnect();
  }

  private canShowTooltip(): boolean {
    return !this.tooltipDisabled && !isUndefined(this.label) && this.label.nativeElement.offsetWidth < this.label.nativeElement.scrollWidth;
  }
}
