import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {RouteUtils} from '@application/routing/route-utils';
import {ISubscriptionService, SUBSCRIPTION} from '../subscription/subscription.interface';

@Injectable()
export class CanActivatePermissionGuard implements CanActivate {
  private _subscriptionService: ISubscriptionService;
  private _router: Router;

  public constructor(@Inject(SUBSCRIPTION) subscriptionService: ISubscriptionService, router: Router) {
    this._subscriptionService = subscriptionService;
    this._router = router;
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const hasRequiredPermission = this._subscriptionService.hasPermission(route.data.modulePermission);

    if (!hasRequiredPermission) {
      this._router.navigateByUrl(RouteUtils.paths.forbidden.absolutePath);
    }

    return hasRequiredPermission;
  }
}
