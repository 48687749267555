<mat-card class="vdw-card">
  <mat-card-header fxLayout="row" fxLayoutAlign="start center">
    <mat-card-title>
      <app-vdw-title [titleIcon]="titleIcon" [title]="title"></app-vdw-title>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="card-content" fxLayout="column" fxLayoutGap="16px">
    <ng-content></ng-content>
    <app-progress-button
      *ngIf="canShowButton"
      class="submit-button"
      [label]="buttonLabel"
      [alwaysShowLabel]="true"
      [fullWidth]="false"
      (progressClick)="this.clickButtonEvent.emit()"
    ></app-progress-button>
  </mat-card-content>
</mat-card>
