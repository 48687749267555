export enum CustomerVerificationStatus {
  NONE = 1,
  PENDING_SENT = 2,
  SENT,
  CONFIRMED,
  END,
  END_PENDING_SIGNED_COPY,
  REJECTED,
  REJECTED_END,
  REJECTED_REDO,
  CANCELLED
}

export const CUSTOMER_VERIFICATION_VERIFIED_STATUSES = [CustomerVerificationStatus.CONFIRMED, CustomerVerificationStatus.END, CustomerVerificationStatus.END_PENDING_SIGNED_COPY];
export const CUSTOMER_FEEDBACK_APPROVAL_STATUSES = [CustomerVerificationStatus.CONFIRMED, CustomerVerificationStatus.REJECTED_END, CustomerVerificationStatus.REJECTED_REDO];
export const CUSTOMER_FEEDBACK_REJECTED_STATUSES = [CustomerVerificationStatus.REJECTED_END, CustomerVerificationStatus.REJECTED_REDO];
export const CUSTOMER_FEEDBACK_UPDATABLE_STATUSES = [CustomerVerificationStatus.PENDING_SENT, CustomerVerificationStatus.SENT, CustomerVerificationStatus.REJECTED];
export const CUSTOMER_FEEDBACK_SHOW_REMARK_STATUSES = [CustomerVerificationStatus.REJECTED, CustomerVerificationStatus.REJECTED_END, CustomerVerificationStatus.REJECTED_REDO];
export const CUSTOMER_FEEDBACK_CONVERTABLE_STATUSES = [CustomerVerificationStatus.END, CustomerVerificationStatus.END_PENDING_SIGNED_COPY];
export const CUSTOMER_FEEDBACK_SALESFORCE_STATUSES = [
  CustomerVerificationStatus.SENT,
  CustomerVerificationStatus.CONFIRMED,
  CustomerVerificationStatus.END,
  CustomerVerificationStatus.END_PENDING_SIGNED_COPY,
  CustomerVerificationStatus.REJECTED,
  CustomerVerificationStatus.REJECTED_END,
  CustomerVerificationStatus.REJECTED_REDO,
  CustomerVerificationStatus.CANCELLED
];
export const EDITABLE_CUSTOMER_FEEDBACK_SALESFORCE_STATUSES = [CustomerVerificationStatus.REJECTED_END, CustomerVerificationStatus.CANCELLED];
