import {InjectionToken} from '@angular/core';
import {GeneralResponse} from '@infrastructure/general.response';
import {Observable} from 'rxjs';
import {LocalizedName} from '../localized-name';
import {UserResponse} from '../organization/user/user.response';
import {AuthRequest} from './authentication.request';
import {AuthResponse} from './authentication.response';
import {ChangePasswordRequest} from './change-password.request';
import {CheckAccountResponse} from './check-account.response';
import {RefreshTokenResponse} from './refresh-token.response';
import {ResetPasswordRequest} from './reset-password.request';
import {TokenResponse} from './token.response';

export const AUTHENTICATION = new InjectionToken<IAuthenticationService>('IAuthenticationService');

export interface IAuthenticationService {
  logout(): Observable<void>;
  getNodeId(): string;
  setNodeId(userNode: number): void;
  getUserCode(): string;
  setUserCode(userCode: string): Observable<void>;
  getUserName(): any;
  setUserName(userName: LocalizedName[]): Observable<void>;
  setAuthenticationParameters(authenticationParameters: string): void;
  getRedirectAfterAuthentication(): string;
  setRedirectAfterAuthentication(url: string): void;

  checkAccountValidity(phoneNumber: string): Observable<CheckAccountResponse>;
  authenticate(request: AuthRequest): Observable<AuthResponse>;
  resetPassword(request: ResetPasswordRequest): Observable<boolean>;
  getProfile(): Observable<UserResponse>;
  changePassword(request: ChangePasswordRequest): Observable<GeneralResponse>;
  refreshToken(): Observable<RefreshTokenResponse>;
  decodeToken(accessToken): TokenResponse;
  getIsSystemAdmin(): Observable<boolean>;
  removeLocalStorage(): void;
}
