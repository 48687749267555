import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingState {
  private history = [];
  private _router: Router;

  constructor(router: Router) {
    this._router = router;
  }

  public loadRouting(): void {
    this._router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(({urlAfterRedirects}: NavigationEnd) => {
      this.history = [...this.history, urlAfterRedirects];
    });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2];
  }
}
