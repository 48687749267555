import {Component, Input, Output} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss']
})
export class ProgressButtonComponent {
  @Input() public alwaysShowLabel = false;
  @Input() public inProgress = false;
  @Input() public label = 'GENERAL.ACTIONS.SAVE';
  @Input() public disabled = false;
  @Input() public raisedButton = true;
  @Input() public labelReplaceableValues = {};
  @Input() public tabIndex = 0;
  @Input() public fullWidth = false;
  @Input() public invalid = false;
  @Output() public progressClick: Subject<void> = new Subject<void>();

  public showLabel(): boolean {
    return this.alwaysShowLabel || !this.inProgress;
  }

  public isInProgress(): boolean {
    return this.inProgress;
  }

  public isDisabled(): boolean {
    return this.disabled;
  }

  public getButtonWidth(): string {
    return this.fullWidth ? '100%' : 'auto';
  }

  public startProgress(): void {
    if (!this.isInProgress()) {
      this.progressClick.next();
    }
  }

  public isRaisedButton(): boolean {
    return this.raisedButton;
  }
}
