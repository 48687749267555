import {RouteBranch} from '../route-branch';
import {ErpCustomerRoute} from './erp-customer-route';

export class ErpRoute extends RouteBranch {
  private _erpCustomer: ErpCustomerRoute;

  public get erpCustomer(): ErpCustomerRoute {
    return this._erpCustomer;
  }

  public set erpCustomer(value: ErpCustomerRoute) {
    this.replace(this._erpCustomer, value);
    this._erpCustomer = value;
  }
}
