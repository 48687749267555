<div class="header">
  <div class="logo logo-dark">
    <a href="" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="start center">
      <img [src]="getHeaderLogo()" alt="Logo" />
    </a>
  </div>
  <div class="nav-wrap">
    <div (click)="openNotificationListing()" class="broadcast-message" fxLayout="row" fxLayoutAlign="center center" fxFlex="56px">
      <mat-icon #notificationButton svgIcon="notification" [matBadgeHidden]="totalUnreadNotification <= 0" [matBadge]="totalUnreadNotification" matBadgeColor="accent" matBadgeSize="small"></mat-icon>

      <div #notificationListing class="dropdown-content" *ngIf="isShowNotificationListing">
        <div class="triangle"></div>

        <div class="header-text-container">
          <div class="header-text">{{ 'MESSAGE_CENTRE.NOTIFICATION.TITLE' | translate }}</div>
        </div>

        <app-loading-file *ngIf="isLoading"></app-loading-file>

        <ng-container *ngIf="!isLoading">
          <ng-container *ngIf="notificationOverviewResponse.length > 0">
            <ng-container *ngFor="let notification of notificationOverviewResponse">
              <div
                class="message-detail"
                (click)="onRouteClicked(notification.module, notification.entityId, notification.id, notification.isRead)"
                [ngStyle]="{background: notification.isRead ? 'transparent' : '#cad6ff4d'}"
                fxLayoutGap="8px"
                fxLayout="row"
              >
                <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10">
                  <mat-icon style="width: 32px; height: 32px" [svgIcon]="notification.module | notificationIcon"></mat-icon>
                </div>

                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="50">
                  <div fxLayout="row">
                    <span style="font-weight: bold">{{ notification.module | notificationTitle }}</span>
                  </div>
                  <div fxLayout="row">
                    <p class="truncate">{{ notification.content }}</p>
                  </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="start end" fxFlex="40">
                  <div fxLayout="row" style="text-align: end">{{ notification.createdAt | date }}</div>
                  <mat-icon *ngIf="!notification.isRead" class="unread-status"></mat-icon>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="notificationOverviewResponse.length <= 0">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" style="height: 80%">
              <div fxLayout="row">
                <img src="/assets/images/illustration/notification-bell.svg" alt="loading-image" />
              </div>
              <div style="color: #13296d99; font-size: 17px; font-weight: 400" fxLayout="row" fxLayoutAlign="center center">
                {{ 'MESSAGE_CENTRE.NOTIFICATION.NO_NOTIFICATION_RECEIVED' | translate }}
              </div>
            </div>
          </ng-container>

          <div fxLayout="row" fxLayoutAlign="center center" class="footer-container">
            <a (click)="navigateToNotificationListing()" class="more">{{ 'GENERAL.ACTIONS.VIEW_MORE' | translate }}</a>
          </div>
        </ng-container>
      </div>
    </div>

    <div [hidden]="!hasPermission" class="broadcast-message" fxLayout="row" fxLayoutAlign="center center" fxFlex="56px">
      <mat-icon svgIcon="mail" (click)="navigateToBroadcastMessage()"></mat-icon>
    </div>
    <div class="nav-right" fxLayout="row" (click)="selectMenuPanel.open()">
      <div class="avatar-container" fxLayout="column" fxFlex="32px">
        <div class="avatar-wrapper" fxLayoutAlign="center center" [ngClass]="avatarUrl === null ? 'default-avatar' : 'avatar-url'">
          <img *ngIf="avatarUrl" [src]="avatarUrl" />
          <span *ngIf="!avatarUrl">{{ getShortName() }}</span>
        </div>
      </div>
      <div fxLayout="column" fxFlex="80px">
        <span #userName class="user-name" [matTooltip]="getName()" [matTooltipDisabled]="!isShowToolTip">{{ getName() }} </span>
      </div>
      <mat-select class="select-menu-arrow" fxLayout="column" #selectMenuPanel>
        <mat-option (click)="navigateToProfile()"><mat-icon class="select-menu-icon" svgIcon="person-4"></mat-icon>{{ 'PROFILE.PROFILE' | translate }}</mat-option>
        <mat-option (click)="navigateToLogout()"><mat-icon class="select-menu-icon" svgIcon="logout"></mat-icon> {{ 'AUTHENTICATION.LOGOUT' | translate }} </mat-option>
      </mat-select>
    </div>
  </div>
</div>
