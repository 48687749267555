import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-vdw-title',
  templateUrl: './vdw-title.component.html',
  styleUrls: ['./vdw-title.component.scss']
})
export class VdwTitleComponent {
  @Input() public titleIcon: string;
  @Input() public title: string;
}
