import {isEmpty} from 'lodash-es';
import {ISelectFilterOption} from './select-filter-option';

export interface IAdvancedSearchFilter {
  propertyName: string;
  propertyOptions: ISelectFilterOption[];
  title: string;
  summary?: string;
  selectedValues?: any | any[];
  isSelected: boolean;
  setDefaultValues(): void;
}
export class AdvancedSearchFilter implements IAdvancedSearchFilter {
  private _propertyName: string;
  private _propertyOptions: ISelectFilterOption[];
  private _title: string;
  private _summary: string;
  private _selectedValues?: any[];
  private _isSelected: boolean;

  public constructor(propertyName: string, propertyOptions: ISelectFilterOption[], title: string, selectedValues: any[] = [], isSelected: boolean = false) {
    this._propertyName = propertyName;
    this._title = title;
    this._propertyOptions = propertyOptions;
    this._selectedValues = selectedValues;
    this._isSelected = isSelected;
  }

  public get propertyName(): string {
    return this._propertyName;
  }

  public get propertyOptions(): ISelectFilterOption[] {
    return this._propertyOptions;
  }

  public get title(): string {
    return this._title;
  }

  public get summary(): string {
    return this._summary;
  }

  public set summary(summary: string) {
    this._summary = summary;
  }

  public get selectedValues(): any[] {
    return this._selectedValues;
  }

  public set selectedValues(selectedValues: any[]) {
    this._selectedValues = selectedValues;
  }

  public get isSelected(): boolean {
    return this._isSelected;
  }

  public set isSelected(isSelected: boolean) {
    this._isSelected = isSelected;
  }

  public selectAll(): void {
    this._selectedValues = this._propertyOptions.map((option: ISelectFilterOption) => option.value);
  }

  public clear(): void {
    this._selectedValues = [];
  }

  public setDefaultValues(): void {
    this._isSelected ? this.selectAll() : this.clear();
    this._summary = this._selectedValues.length === this.propertyOptions.length ? 'GENERAL.ADVANCED_SEARCH.ALL' : !isEmpty(this._selectedValues) ? `+${this._selectedValues.length}` : '';
  }
}
