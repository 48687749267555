import {Pipe, PipeTransform} from '@angular/core';
import {TimezoneUtils} from '@application/helper/timezone-utils';
import {isNil, isUndefined} from 'lodash-es';
import moment from 'moment-timezone';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  private readonly defaultDateFormat = 'YYYY-MM-DD HH:mm';

  public transform(value: Date | string, format?: string): string {
    if (isUndefined(value) || isNil(value)) {
      return 'N/A';
    }

    const dateValue = typeof value == 'string' ? new Date(value) : value;
    const dateFormat = format || this.defaultDateFormat;

    return moment(TimezoneUtils.convertToDateWithTimeZoneOffset(dateValue)).format(dateFormat);
  }
}
