import {Module} from '@domain/organization/role/module.enum';
import {Permission} from '@domain/organization/role/permission.enum';
import {first, isEqual, isNil, size, some} from 'lodash-es';
import {RouteNode} from './route-node';

export class RouteBranch extends RouteNode {
  private _children: RouteNode[] = [];

  public constructor(path: string, module?: Module, requiredPermission?: Permission, isMobileFriendly: boolean = false) {
    super(path, module, requiredPermission, isMobileFriendly);
  }

  public get children(): RouteNode[] {
    return this._children;
  }

  public add(node: RouteNode): void {
    this._children.push(node);
    node.parent = this;
  }

  public remove(node: RouteNode): void {
    const componentIndex = this._children.indexOf(node);
    if (componentIndex >= 0) {
      this._children.splice(componentIndex, 1);
      node.parent = null;
    }
  }

  public replace(node: RouteNode, replacement: RouteNode): void {
    if (!isEqual(node, replacement)) {
      if (!isNil(node)) {
        this.remove(node);
      }
      this.add(replacement);
    }
  }

  public isKnownPath(pathSegments: string[]): boolean {
    let result = false;
    const firstPathSegment: string = first(pathSegments);
    result = this.matchesPath(firstPathSegment);
    if (result && size(pathSegments) > 1) {
      pathSegments.splice(0, this.hasParameter ? this.amountOfRouteParameters : 1);
      result = some(this._children, (routeNode: RouteNode) => {
        return routeNode.isKnownPath(pathSegments);
      });
    }
    return result;
  }
}
