import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {DeviceOrientation} from '@application/responsiveness/device-orientation.enum';
import {Device} from '@application/responsiveness/device.enum';
import {ResponsivenessViewMode} from '@application/responsiveness/responsiveness-view.mode';
import {isEqual} from 'lodash-es';
import {Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreakpointResponsivenessViewModeService implements ResponsivenessViewMode {
  private static readonly portraitMobileKeyboardOffsetInPx = 100;
  private static mediaQueryForPhone = '(max-width: 599px)';
  private static mediaQueryForTablet = '(min-width: 600px) and (max-width: 1279px)';
  private static mediaQueryForDesktop = '(min-width: 1280px)';
  private static mediaQueryForPortrait = '(orientation: portrait)';
  private breakpointObserver: BreakpointObserver;
  private deviceChangeSubject: Subject<Device>;
  private currentDevice: Device;
  private currentDeviceOrientation: DeviceOrientation;
  private readonly document: Document;

  public constructor(breakpointObserver: BreakpointObserver, @Inject(DOCUMENT) document: Document) {
    this.breakpointObserver = breakpointObserver;
    this.document = document;
    this.deviceChangeSubject = new Subject<Device>();

    this.observeDefaultBreakpointChanges();
    this.observeDefaultOrientationChanges();
  }

  public get isDesktop(): boolean {
    return isEqual(this.currentDevice, Device.DESKTOP);
  }

  public get isPhone(): boolean {
    return isEqual(this.currentDevice, Device.PHONE);
  }

  public get isTablet(): boolean {
    return isEqual(this.currentDevice, Device.TABLET);
  }

  public get isPortrait(): boolean {
    return isEqual(this.currentDeviceOrientation, DeviceOrientation.PORTRAIT);
  }

  public observeBreakpointChanges(mediaQuery: string | string[]): Observable<BreakpointState> {
    return this.breakpointObserver.observe(mediaQuery);
  }

  public matchesBreakpoint(mediaQuery: string): boolean {
    return this.breakpointObserver.isMatched(mediaQuery);
  }

  public observeDeviceChanges(): Observable<Device> {
    return this.deviceChangeSubject.asObservable().pipe(startWith(this.currentDevice));
  }

  private observeDefaultOrientationChanges(): void {
    this.setInitialDeviceOrientation();

    this.breakpointObserver.observe([BreakpointResponsivenessViewModeService.mediaQueryForPortrait]).subscribe((breakpointState: BreakpointState) => {
      const isPortrait: boolean = breakpointState.matches;
      if (this.isNotPortraitMobileWithKeyboardOpened(isPortrait) || isPortrait) {
        this.currentDeviceOrientation = isPortrait ? DeviceOrientation.PORTRAIT : DeviceOrientation.LANDSCAPE;
      }
    });
  }

  private setInitialDeviceOrientation() {
    const isPortrait: boolean = this.breakpointObserver.isMatched(BreakpointResponsivenessViewModeService.mediaQueryForPortrait);
    if (this.isNotPortraitMobileWithKeyboardOpened(isPortrait) || isPortrait) {
      this.currentDeviceOrientation = isPortrait ? DeviceOrientation.PORTRAIT : DeviceOrientation.LANDSCAPE;
    }
  }

  private isNotPortraitMobileWithKeyboardOpened(isPortrait: boolean): boolean {
    return !isPortrait && this.document.body.clientWidth > this.document.body.clientHeight + BreakpointResponsivenessViewModeService.portraitMobileKeyboardOffsetInPx;
  }

  private observeDefaultBreakpointChanges(): void {
    this.setInitialDevice();

    this.breakpointObserver.observe([BreakpointResponsivenessViewModeService.mediaQueryForPhone]).subscribe((breakpointState: BreakpointState) => {
      if (breakpointState.matches) {
        this.currentDevice = Device.PHONE;
        this.deviceChangeSubject.next(this.currentDevice);
      }
    });

    this.breakpointObserver.observe([BreakpointResponsivenessViewModeService.mediaQueryForTablet]).subscribe((breakpointState: BreakpointState) => {
      if (breakpointState.matches) {
        this.currentDevice = Device.TABLET;
        this.deviceChangeSubject.next(this.currentDevice);
      }
    });

    this.breakpointObserver.observe([BreakpointResponsivenessViewModeService.mediaQueryForDesktop]).subscribe((breakpointState: BreakpointState) => {
      if (breakpointState.matches) {
        this.currentDevice = Device.DESKTOP;
        this.deviceChangeSubject.next(this.currentDevice);
      }
    });
  }

  private setInitialDevice() {
    if (this.breakpointObserver.isMatched(BreakpointResponsivenessViewModeService.mediaQueryForPhone)) {
      this.currentDevice = Device.PHONE;
    } else if (this.breakpointObserver.isMatched(BreakpointResponsivenessViewModeService.mediaQueryForTablet)) {
      this.currentDevice = Device.TABLET;
    } else {
      this.currentDevice = Device.DESKTOP;
    }
  }
}
