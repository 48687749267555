<mat-card-content class="auth-template-wrapper">
  <mat-grid-list cols="2" rowHeight="fit">
    <mat-grid-tile [colspan]="1" [rowspan]="1" class="auth-template-title-grid">
      <label class="auth-template-title">{{ label | translate: labelReplaceableValues }}</label>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="7" class="auth-template-content-grid">
      <ng-content></ng-content>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="6" class="auth-template-illustration-grid">
      <div [className]="'auth-template-illustration ' + getIllustrationName()"></div>
    </mat-grid-tile>
  </mat-grid-list>
</mat-card-content>
