import {Directive, Inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {ISubscriptionService, SUBSCRIPTION} from '@application/helper/subscription/subscription.interface';
import {Module} from '@domain/organization/role/module.enum';
import {Permission} from '@domain/organization/role/permission.enum';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {
  private readonly _subscriptionService: ISubscriptionService;
  private readonly _templateRef: TemplateRef<any>;
  private _viewContainer: ViewContainerRef;

  public constructor(@Inject(SUBSCRIPTION) subscriptionService: ISubscriptionService, templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
    this._subscriptionService = subscriptionService;
    this._templateRef = templateRef;
    this._viewContainer = viewContainer;
  }

  @Input()
  public set appHasPermission(modulePermission: IModulePermission) {
    if (this._subscriptionService.hasPermission(modulePermission)) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }
}

export interface IModulePermission {
  module: Module;
  requiredPermission: Permission;
}
