import {Inject, Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {RouteUtils} from '@application/routing/route-utils';
import {AUTHENTICATION, IAuthenticationService} from '@infrastructure/http/authentication/http-authentication.interface';
import {isNil} from 'lodash-es';

@Injectable()
export class CanActivateDefaultPathGuard implements CanActivate {
  private readonly _router: Router;
  private readonly _authenticationService: IAuthenticationService;

  public constructor(router: Router, @Inject(AUTHENTICATION) authentication: IAuthenticationService) {
    this._router = router;
    this._authenticationService = authentication;
  }

  public canActivate(): boolean {
    const currentUser = this._authenticationService.getUserCode();

    if (isNil(currentUser)) {
      this._router.navigateByUrl(RouteUtils.paths.authentication.login.absolutePath);
    } else {
      this._router.navigateByUrl(RouteUtils.getDefaultRoute());
    }
    return false;
  }
}
